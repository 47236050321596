import React from 'react';
import PodcastIcon from "../images/microphone_icon.png";
import VideoIcon from "../images/video_icon.png";
import BlogIcon from "../images/blog_icon.png";

const MediaCard = ({itemArray, content_type}) => {

   
    return (
    <div>
        { content_type === "blog" ? 
             
        <div className="flex p-10 bg-white border border-black divide-x divide-green-500 rounded-lg shadow font-body bg-opacity-95">
            <div className="flex flex-col items-center space-y-8">
                <img src={BlogIcon} className="w-1/10" alt="Blog" />
                <h1 className="text-xl font-bold">BLOGS</h1>
            </div>
            
            <div className="py-5 pl-10 my-auto ml-20 space-y-3">
            { itemArray.map((item, idx) => (
                <div key={idx}>
        
                <h4><a href={item.url} className="text-xl" target="_blank" rel="noopener noreferrer">{item.name}</a></h4>
                </div>
            ))} 
            </div>
        
        </div>:''
        }
        { content_type === "video" ? 
             
        <div className="flex p-10 bg-white border border-black divide-x divide-green-500 rounded-lg shadow font-body bg-opacity-95">
            <div className="flex flex-col items-center space-y-8">
                <img src={VideoIcon} className="w-1/10" alt="Video" />
                <h1 className="text-xl font-bold">VIDEOS</h1>
            </div>
            
            <div className="py-5 pl-10 my-auto ml-20 space-y-3">
            { itemArray.map((item, idx) => (
                <div key={idx}>
        
                <h4><a href={item.url} target="_blank" rel="noopener noreferrer" className="text-xl">{item.name}</a></h4>
                </div>
            ))} 
            </div>
        
        </div>:''
        }
        { content_type === "podcast" ? 
             
        <div className="flex p-10 bg-white border border-black divide-x divide-green-500 rounded-lg shadow font-body bg-opacity-95">
            <div className="flex flex-col items-center space-y-8">
                <img src={PodcastIcon} className="w-1/10" alt="Blog" />
                <h1 className="text-xl font-bold">PODCASTS</h1>
            </div>
            
            <div className="py-5 pl-10 my-auto ml-20 space-y-3">
            { itemArray.map((item, idx) => (
                <div key={idx}>
        
                <h4><a href={item.url} className="text-xl" target="_blank" rel="noopener noreferrer">{item.name}</a></h4>
                </div>
            ))} 
            </div>
        
        </div>:''
        }
    
    
    </div>
        
   
       
    )
}

export default MediaCard
