import React from "react";

const BlogCard = ({ title, description, author, atf_image, slug, pubDate }) => {
  if (atf_image === null || atf_image === "") {
    atf_image =
      "https://mongodb-devhub-cms.s3.us-west-1.amazonaws.com/1_BC_Lta_H2n_Emjc_L3v_T668_Ccg_d0e90bb75f.png";
  } else if (atf_image.includes("%{")) {
    atf_image =
      "https://mongodb-devhub-cms.s3.us-west-1.amazonaws.com/ATF_720x720_17fd9d891f.png";
  } else if (atf_image.startsWith("/images")) {
    atf_image = `https://developer.mongodb.com${atf_image}`;
  }
  if (slug.startsWith("/")) {
    slug = `https://www.mongodb.com/developer${slug}`;
  } else {
    slug = `https://www.mongodb.com/developer/${slug}`;
  }

  return (
    <React.Fragment>
      <a href={slug} target="_blank" rel="noopener noreferrer">
        <div className="relative flex flex-col w-full overflow-auto transition duration-500 transform border border-black rounded-sm shadow-xl h-96 hover:scale-105 font-body">
          <h4 className="p-2 text-center">
            <b>{title}</b>
          </h4>
          <h4 className="text-center text-indigo-900"> by {author}</h4>

          <img src={atf_image} className="object-contain h-48" alt="blog" />
          <div className="flex flex-col p-2 content justify-content space-between">
            <br></br>
            <h4 className="overflow-auto">{description}</h4>
          </div>
          <span className="sticky bottom-0 inline-block w-full h-10 p-5 overflow-hidden text-center text-white align-middle bg-william-700">
            {pubDate}
          </span>
        </div>
      </a>
    </React.Fragment>
  );
};

export default BlogCard;
