import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";

// COMPONENTS
import BlogCard from "../components/BlogCard";
import SearchBar from "../components/SearchBar";
import Pagination from "../components/Pagination";

//HOOK
import { useBlogsFetch } from "../hooks/useBlogsFetch.js";
const ProductOptions = [
  { label: "Atlas Search", value: "Atlas Search" },
  { label: "Atlas", value: "Atlas" },
  { label: "MongoDB", value: "MongoDB" },
  { label: "Realm", value: "Realm" },
  { label: "Charts", value: "Charts" },
  { label: "Mobile", value: "Mobile" },
  { label: "Data Lake", value: "Data Lake" },
  { label: "Compass", value: "Compass" },
  { label: "Online Archive", value: "Online Archive" },
  { label: "BI Connector", value: "BI Connector" },
  { label: "VS Code", value: "VS Code" },
  { label: "Realm Studio", value: "Realm Studio" },
];

const BlogsPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [prods, setProds] = useState("");

  const selectInputRef = useRef();

  const { state } = useBlogsFetch(searchTerm, currentPage, prods);

  const { items, maxPages } = state;

  // handle onChange event of the dropdown
  const handleSubmit = () => {
    console.log("Click!");
    let prodArray = [];
    for (let i = 0; i < products.length; i++) {
      prodArray.push(products[i].value);
    }
    setProds(encodeURI(prodArray));
    //  console.log(prods);
    setCurrentPage(1);
  };

  return (
    <React.Fragment>
      <div className="flex items-center justify-center bg-gradient-to-r from-blue-300 to-blue-800 h-36">
        <h1 className="text-3xl font-bold text-white font-body">
          Developer Hub Blogs
        </h1>
      </div>

      <div className="container p-10 mx-auto">
        <div className="flex justify-between w-full">
          <div className="flex-grow">
            <SearchBar
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              onChange={setSearchTerm}
              page="blogs"
            />
          </div>
          <div className="flex-grow-0 w-1/5 my-auto text-2xl">
            <Select
              ref={selectInputRef}
              className=""
              options={ProductOptions}
              onChange={setProducts}
              isMulti
              placeholder="Select Products"
              isSearchable
            />
          </div>
          <div className="my-auto">
            <button
              className="flex-grow-0 px-4 py-2 ml-5 text-2xl text-white rounded-full shadow-md focus:outline-none bg-mongo hover:shadow-2xl hover:bg-mongo-700"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>

        {searchTerm && (
          <div className="flex items-center m-10 space-x-10">
            <h1 className="text-2xl">Search Results:</h1>
            <h1 className="mr-10 text-4xl font-bold text-mongo-500">
              {searchTerm}
            </h1>
          </div>
        )}

        <div className="grid grid-cols-4 gap-6 p-2 mt-10">
          {items.map((item) => {
            var authors = item.author_names.join(", ");
            return (
              <BlogCard
                key={item._id}
                title={item.title}
                author={authors}
                description={item.description}
                atf_image={item.atf_image}
                slug={item.slug}
                pubDate={item.pubDate}
              />
            );
          })}
        </div>

        <Pagination
          maxPages={maxPages}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </div>
    </React.Fragment>
  );
};

export default BlogsPage;
