import React from 'react';
import { Link } from 'react-router-dom';

const BundleCard = ({image, title, author, description, bundleId, clickable, 
    bundle_items }) => {
      
    return (
        <>
        <Link to={`/${bundleId}`} className="relative w-full transition duration-500 transform bg-white border rounded-lg shadow-lg hover:scale-110 border-william-700 font-body">
                
                <div className="px-6 py-4 mb-12 overflow-auto max-h-80">
                    <h4 className="mb-3 text-2xl text-center">{title}</h4>
                    <h5 className="mb-3 text-lg text-center text-mongo-700">{author}</h5>
                    <p className="mt-4">{description}</p>     
                </div>

                <div className="fixed inset-x-0 bottom-0 flex justify-between px-8 bg-tolopea-500">
                    { (bundle_items.includes("presentation"))  ? <svg xmlns="http://www.w3.org/2000/svg" className="w-12" fill="none" viewBox="0 0 24 24" stroke="white">
                       <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M8 13v-1m4 1v-3m4 3V8M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
                      </svg> : null }
                    
                    { (bundle_items.includes("blog")) ? <svg xmlns="http://www.w3.org/2000/svg" className="w-12" fill="none" viewBox="0 0 24 24" stroke="white">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                      </svg> : null }     
                                
                    { (bundle_items.includes("video")) ? <svg xmlns="http://www.w3.org/2000/svg" className="w-12" fill="none" viewBox="0 0 24 24" stroke="white">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                      </svg> : null }    
                
                    { (bundle_items.includes("podcast")) ? <svg xmlns="http://www.w3.org/2000/svg" className="w-12" fill="none" viewBox="0 0 24 24" stroke="white">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z" />
                      </svg> : null }

                    { (bundle_items.includes("git")) ? <svg role="img" className="text-white fill-current w-11" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 
                    17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 
                    0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 
                    2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"/></svg>  : null }
                        
                </div> 
        </Link>
            
        </>
    )
}

export default BundleCard
