import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import ChartsDevHub from "../components/chart-components/ChartsDevHub";
import ChartsYoutube from "../components/chart-components/ChartsYoutube";
import NavCharts from "../components/chart-components/NavCharts";

const ChartsPage = () => <>
  <div className="flex items-center justify-center bg-gradient-to-r from-teal-600 to-teal-900 h-36">
    <h1 className="text-3xl font-bold text-white font-body">Developer Advocacy Charts</h1>
  </div>
  <Router basename="/charts">
    <NavCharts/>
    <Switch>
      <Route exact path='/devhub' component={ChartsDevHub}/>
      <Route exact path='/youtube' component={ChartsYoutube}/>
    </Switch>
  </Router>
</>;

export default ChartsPage;
