import React from 'react';
import AllPublishedBlogs from '../../components/chart-components/AllPublishedBlogs';
import ContentCount from '../../components/chart-components/ContentCount'
import SimpleChart from "./SimpleChart";

const ChartsDevHub = () => {
  return <>
    <div className="container w-full px-10 mx-auto space-y-10">
      <SimpleChart height="400px" width="1500px" chartId="0b3cd7a5-c949-40ec-8514-708de209e391"/>
      <hr className="h-1 bg-green-800"/>
      <div className="flex justify-around divide-x divide-tolopea-500">
        <SimpleChart height="400px" width="700px" chartId="28904687-8652-4d1f-9b6d-35332ec78f33"/>
        <ContentCount/>
        <SimpleChart height="400px" width="400px" chartId="596e8efb-9c5d-49b6-aff4-da60ad9bf5cc"/>
      </div>
      <hr className="h-1 bg-green-800"/>
      <AllPublishedBlogs/>
    </div>
  </>;
}

export default ChartsDevHub;
