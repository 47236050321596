import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {CurrentUserContext} from '../components/CurrentUserContext';
import RealmHero from '../images/RealmHero.png';
import axios from "axios";

const UpdateAuthorPage = () => {
    const { authorId } = useParams();

    const [authorState, setAuthorState] = useState({})
    const [submitted, setSubmitted] = useState(false);
    const [valid, setValid] = useState(false);
   
    const history = useHistory();

    useEffect(()=>{
        const fetchAuthor = async() =>{
            const endpoint=`https://webhooks.mongodb-realm.com/api/client/v2.0/app/da_share-khckc/service/search/incoming_webhook/get-author-by-id?id=${authorId}`
            const {data} = await axios.get(endpoint);
            setAuthorState(data);
        }
        fetchAuthor(authorId);
    }, []);

    useEffect(()=>{
        if (submitted===false) return;
        axios
        .post(
            `https://webhooks.mongodb-realm.com/api/client/v2.0/app/da_share-khckc/service/da_content/incoming_webhook/updateBundler?id=${authorId}`,
            authorState
        ).then((res) => {
            console.log("RESPONSE AFTER POST");
            if (res.data.OK === true){
                setValid(true);
            } else {
                console.log("OOPS! Have Karen check the logs.");
            }
        });   
        setSubmitted(false);  
    //eslint-disable-next-line
    },[submitted]);
    

   const handleNameChange = (e) =>{
       setAuthorState({...authorState, name:e.target.value})
   }
   const handleEmailChange = (e) =>{
    if (authorId === "6050d50958ae174c67c35c45"){
        setAuthorState({...authorState, email:e.target.value})
    }
    else console.log("You cannot change email address.");
    }
   const handleTwitterChange = (e) =>{
    setAuthorState({...authorState, twitter:e.target.value})
    }
    const handleLinkedinChange = (e) =>{
        setAuthorState({...authorState, linkedin:e.target.value})
        }
    const handleBioChange = (e) =>{
        setAuthorState({...authorState, bio:e.target.value})
    }
    const handleImageChange = (e) =>{
        setAuthorState({...authorState, image:e.target.value})
    }
    const handleTitleChange = (e) =>{
        setAuthorState({...authorState, title:e.target.value})
    }
    const handleSocialChange = (e) =>{
        setAuthorState({...authorState, social:e.target.value})
    }
    const handleGitChange = (e) =>{
        setAuthorState({...authorState, git:e.target.value})
    }

    const handleBundlerChange = (e) =>{
        setAuthorState({...authorState, bundler:e.target.checked})  
    }

    const handleSubmit = (e) =>{
        e.preventDefault();
        setSubmitted(true);
        console.log("SUBMITTED AUTHOR DATA");
        console.log(authorState);
    }
    
    return (
        <>
            <div className="flex text-center bg-gradient-to-r from-teal-800 to-indigo-900 h-36">
                <div className="mx-auto my-auto text-3xl font-bold text-white font-body">Update Author Details: {authorState.name}</div>
            </div>

            <div className="container flex px-5 py-10 mx-auto my-10 px-50">
                <div className="w-full p-10 mx-auto bg-white rounded-lg shadow-xl">


            <form onSubmit={handleSubmit}>
                { valid ?  
                    <div className="p-4 mb-8 text-xl text-center bg-yellow-300 success-message">Your author details have been updated!  🎉 </div>
                    :null
                }
                <div className="flex justify-evenly">
                <div className="w-1/3 left">
                        <div className="mb-5">
                            <label for="name" class="block mb-2 font-bold text-gray-800">Name</label> 
                            <input 
                                type="text" 
                                name="name" 
                                placeholder="Enter Name" 
                                onChange={handleNameChange}
                                value={authorState.name}
                                required
                                className="flex-1 w-full py-2 placeholder-gray-300 border-b-2 border-gray-400 outline-none focus:border-green-800"
                            />
                            { submitted && !authorState.name ? <p className="mt-2 text-red-300 text-small">Name is required.</p> : null}
                        </div>

                        <div className="mb-2">
                            <label for="name" class="block mb-2 font-bold text-gray-800">Email</label> 
                            <input 
                                type="email" 
                                name="email" 
                                onChange={handleEmailChange}
                                placeholder="Enter Email" 
                                value={authorState.email}
                                className="flex-1 w-full py-2 placeholder-gray-300 placeholder-opacity-100 border-b-2 border-gray-400 outline-none focus:border-green-800"
                            />
                            {(authorId === "6050d50958ae174c67c35c45") ?
                                <p className="mt-2 text-right text-red-700 text-small">ENTER MONGODB EMAIL.</p>
                                : <p className="mt-2 text-right text-blue-300 text-small">Email cannot change.</p> 
                             }
                        </div>
                        <div className="mb-5">
                            <label for="name" class="block mb-2 font-bold text-gray-800">Title</label> 
                            <input 
                                type="text" 
                                name="title" 
                                placeholder="Enter Title" 
                                onChange={handleTitleChange}
                                value={authorState.title}
                                className="flex-1 w-full py-2 placeholder-gray-300 border-b-2 border-gray-400 outline-none focus:border-green-800"
                            />     
                        </div>
                        <div class="mb-5">
                            <label for="twitter" className="block mb-2 font-bold text-gray-800">Twitter</label> 
                            <input 
                                type="text" 
                                name="twitter" 
                                placeholder="@twitterhandle" 
                                value={authorState.twitter}
                                onChange={handleTwitterChange}
                                className="flex-1 w-full py-2 placeholder-gray-300 border-b-2 border-gray-400 outline-none focus:border-green-800"/>
                        </div>
                        
                        <img className="object-none mx-auto" src={RealmHero} alt="Developer" />

                    </div>
                    <div className="w-1/2 right">
                        <div class="mb-5">
                            <label for="git" className="block mb-2 font-bold text-gray-800">GitHub</label> 
                            <input 
                                type="text" 
                                name="git" 
                                placeholder="Github Username" 
                                value={authorState?.git}
                                onChange={handleGitChange}
                                className="flex-1 w-full py-2 placeholder-gray-300 border-b-2 border-gray-400 outline-none focus:border-green-800"/>
                        </div>

                        <div class="mb-5">
                            <label for="linkedin" className="block mb-2 font-bold text-gray-800">LinkedIn</label> 
                            <input 
                                type="text" 
                                name="linkedin" 
                                placeholder="Linkedin" 
                                value={authorState?.linkedin}
                                onChange={handleLinkedinChange}
                                className="flex-1 w-full py-2 placeholder-gray-300 border-b-2 border-gray-400 outline-none focus:border-green-800"/>
                        </div>
                        <div class="mb-5">
                            <label for="social" className="block mb-2 font-bold text-gray-800">Other Social</label> 
                            <input 
                                type="text" 
                                name="social" 
                                placeholder="ex. TikTok" 
                                value={authorState?.social}
                                onChange={handleSocialChange}
                                className="flex-1 w-full py-2 placeholder-gray-300 border-b-2 border-gray-400 outline-none focus:border-green-800"/>
                        </div>
                    
                        <div class="mb-5">
                            <label for="image" className="block mb-2 font-bold text-gray-800">Profile Image Address</label> 
                            <input 
                                type="text" 
                                name="image" 
                                placeholder="image link" 
                                value={authorState.image}
                                onChange={handleImageChange}
                                className="flex-1 w-full py-2 placeholder-gray-300 border-b-2 border-gray-400 outline-none focus:border-green-800"/>
                        </div>

                        <div class="mb-5">
                            <label for="bio" className="block mb-2 font-bold text-gray-800">Author Bio</label> 
                            <textarea 
                                name="bio" 
                                rows="8"
                                className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
                                onChange={handleBioChange}
                                value={authorState.bio}
                            ></textarea>
                        </div>
            
                        <div className="flex text-xl">
                            <p className="mr-10 text-green-600">Status</p>
                            <div>
                                <label className="inline-flex items-center">
                                    <input 
                                        type="checkbox" 
                                        className="text-indigo-600 form-checkbox"
                                        name="bundler"
                                        checked={authorState.bundler}
                                        onChange={handleBundlerChange}
                                    />
                                    <span className="ml-2">Bundler</span>
                                </label>
                            </div>
                        </div>
                </div>
            </div>
            { !valid ?  
                <button type="submit" 
                    className="block w-full p-4 font-bold text-white bg-purple-800 rounded shadow"
                >Submit</button>
            :   <button type="button" onClick={()=>history.push('/')}
                    className="block w-full p-4 text-xl font-bold text-white bg-purple-800 rounded shadow focus:outline-none"
                >Home</button>}             
            </form>
            </div>
               
            </div>        
        </>
    )
}

export default UpdateAuthorPage
