import { useState, useEffect } from 'react';
import API from './API.js';

const initialState={ 
    items:[],
    maxPages:1
}

export const useBlogsFetch=(searchTerm, page, prods )=>{
    const [state, setState] = useState(initialState);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);



    // this is a function definition that calls another function API.fetchBlogs()
    const fetchBlogs = async (searchTerm="", page=1, prods="") =>{
        
        try{
            setError(false);
            setLoading(true);
            // console.log("TRYING TO FETCHBLOGS");
            // console.log(prods);
            // console.log(page);
            // console.log(searchTerm);


            const blogs = await API.fetchBlogs(searchTerm, page, prods);
            // console.log("FETCHED BLOGS");
            // console.log(blogs);
         
            setState({
                items: blogs.content,
                maxPages: blogs.maxPages
            });  
            
        } catch (error){
            console.log("FAILED TO FETCHBLOGS");
            setError(true);
            console.log(error);
        }
        setLoading(false);
    };

    // initial render and search with 1 page calling the fetchContent function above
    useEffect(()=>{
       // console.log("IN USEEFFECT BLOGS");
        setState(initialState);
      
        
        fetchBlogs(searchTerm, page, prods)
        
    }, [searchTerm, page, prods ]);


   
    return {
        state, 
        loading, 
        error, 
        setIsLoadingMore
	};

};

