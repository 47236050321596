import React, {useContext} from 'react';
import { useHistory } from 'react-router-dom';
import TwitterIcon from '../images/twitter_icon.png';
import {CurrentUserContext} from '../components/CurrentUserContext';

const AuthorSection = ({name, title, image, twitter, email, bio, authorId}) => {
    const { currentUser } = useContext(CurrentUserContext);
    
    console.log("AUTHORID");
    let authIdString = authorId?.$oid;
    console.log(authIdString);
    const history = useHistory();
    return (
    
        <div id="AUTHORSECTION" className="w-1/3 p-5 ml-10 text-2xl text-center border border-indigo-800 rounded shadow-xl">
            <h1>{name}</h1>
            <br></br>
            <img className="z-10 w-1/2 mx-auto mb-2 rounded-full shadow-xl" src={image} alt="advocate" />
            <h1 className="pt-2 text-xl text-mongo-600">{ title}</h1>
            <br></br>
            <div className="flex items-center justify-center space-x-4">
                <span>
                    <img className="w-8 mx-auto" src={TwitterIcon} alt="twitter" />
                </span>
                <span >{twitter}</span>   
            </div>
            <div className="flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" className="text-green w-11" viewBox="0 0 36 36" stroke="green">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76" />
                </svg>
                <span className="text-base md:text-lg">{email}</span>
            </div>

            <div className="flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/></svg>
                <span className="ml-2 text-base md:text-lg">LinkedIn</span>
            </div>
            
            <h1 className="pt-2 text-xl text-tolopea-500">Other Social: </h1>
            <div className="flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 6c-3.313 0-6 2.686-6 6 0 2.651 1.719 4.9 4.104 5.693.3.056.396-.13.396-.289v-1.117c-1.669.363-2.017-.707-2.017-.707-.272-.693-.666-.878-.666-.878-.544-.373.041-.365.041-.365.603.042.92.619.92.619.535.917 1.403.652 1.746.499.054-.388.209-.652.381-.802-1.333-.152-2.733-.667-2.733-2.965 0-.655.234-1.19.618-1.61-.062-.153-.268-.764.058-1.59 0 0 .504-.161 1.65.615.479-.133.992-.199 1.502-.202.51.002 1.023.069 1.503.202 1.146-.776 1.648-.615 1.648-.615.327.826.121 1.437.06 1.588.385.42.617.955.617 1.61 0 2.305-1.404 2.812-2.74 2.96.216.186.412.551.412 1.111v1.646c0 .16.096.347.4.288 2.383-.793 4.1-3.041 4.1-5.691 0-3.314-2.687-6-6-6z"/></svg>
            <span className="ml-2 text-base md:text-lg">GitUser</span>
            </div>

            <br></br>
            <hr style={{
                color: 'darkgreen',
                backgroundColor: 'indigo',
                height: 2,
                borderColor : 'indigo'
            }}/>
            <br></br>
            <h1 className="mb-6 text-base">{bio}</h1>
            { currentUser.profile.email === email ? 
            <button className="flex items-center w-40 h-20 px-6 py-4 mx-auto text-lg text-white transition duration-300 shadow-xl bg-tolopea-400 rounded-xl hover:bg-tolopea-600 focus:outline-none"
                onClick={()=>{
                    console.log("Click");
                    history.push(`/updateAuthor/${authIdString}`);
                }}
                >Update Profile
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                    </svg> 
            </button>  
            : null}
        </div>    
    )
}

export default AuthorSection
