import React, {useState, useEffect} from 'react';

import axios from "axios";
import {useForm, Controller, useFieldArray } from "react-hook-form";
import Select from "react-select";
import DatePicker from "react-datepicker";
import  FormInput  from "../components/form-components/FormInput";

import {useCurrentUser} from '../components/CurrentUserContext.js';


import PodcastIcon from "../images/microphone_icon.png";
import VideoIcon from "../images/video_icon.png";
import BlogIcon from "../images/blog_icon.png";
import "react-datepicker/dist/react-datepicker.css";

const today = new Date();

const defaultValues = {
    title:"",
    author:"",
    description:"",
    instructions:"",
    slides:"",
    git_link:"",
    blogs:[],
    videos:[],
    podcasts:[],
    products: ["Atlas", "MongoDB"],
    date_presented: today
}

const InputPage=()=> {
    const { currentUser } = useCurrentUser();

    const owner = currentUser.profile.email.toLowerCase();
 

    const {register, handleSubmit, control, errors, reset, setValue} = useForm({
        title:"",
        author:"",
        description:"",
        instructions:"",
        slides:"",
        git_link:"",
        blogs:[],
        videos:[],
        podcasts:[],
        products: ["Atlas", "MongoDB"],
        date_presented: today
    });
    const { fields:blogFields, append: blogAppend, remove:blogRemove } = useFieldArray({
        control,
        name:'blogs'
    });
    const { fields:videoFields, append:videoAppend, remove:videoRemove } = useFieldArray({
        control,
        name:'videos'
    });
    const { fields:podcastFields, append:podcastAppend, remove: podcastRemove } = useFieldArray({
        control,
        name:'podcasts'
    });

     
    const [data, setData] = useState(null);

    const [submitted, setSubmitted] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
      
    const handleMultiChange = selectedOption => {    
        let prodArray = [];
        for (let i = 0; i < selectedOption.length; i++){
            prodArray.push(selectedOption[i].value)
        }
        setValue("products", prodArray);
      };

    
   
    const ProductOptions = [ 
        {label: "Atlas Search", value: "Atlas Search"}, 
        {label: "Atlas", value: "Atlas"},
        {label: "MongoDB", value: "MongoDB"},
        {label: "Realm", value: "Realm"},
        {label: "Charts", value: "Charts"},
        {label: "Mobile", value: "Mobile"},
        {label: "Data Lake", value: "Data Lake"},
        {label: "Compass", value: "Compass"},
        {label: "Online Archive", value: "Online Archive"},
        {label: "BI Connector", value: "BI Connector"},
        {label: "VS Code", value: "VS Code"},
        {label: "Realm Studio", value: "Realm Studio"}
    ];


useEffect(()=>{
   if (submitted===false) return;

    axios
    .post(
        "https://webhooks.mongodb-realm.com/api/client/v2.0/app/da_share-khckc/service/da_content/incoming_webhook/addBundle",
        data
    ).then((res) => console.log(res.data));

    console.log("IN USE EFFECT");
   
    setShowConfirmation(true);
    console.log(data);
    
    setSubmitted(false);
     reset({defaultValues})
 
   
 //eslint-disable-next-line
  },[submitted]);
   

  const onSubmit =(formData)=>{
      if (formData.products===undefined){
        console.log("need to update products");
        formData.products=["Atlas", "MongoDB"];
      }
        
    setData({
        data:formData,
        owner:owner
    });
 
    console.log("IN ONSUBMIT");
    setSubmitted(true);
 
    }
        
    return(
        <React.Fragment>
        <div className="flex items-center justify-center bg-gradient-to-r from-pink-700 to-pink-900 h-36">
                <h1 className="text-4xl text-white ">Input Bundle</h1>
        </div>

        {   !showConfirmation ?
        <>
            
        <form className="flex flex-col " onSubmit={handleSubmit(onSubmit)}>
            <div className="flex px-20 py-10 space-x-10 top-row">
                
            <div className="flex flex-col w-1/2 pr-10 space-y-10 BASIC">
            <label className="text-2xl text-teal-900">Basic Bundle Information</label>
                <FormInput
                    type="text"
                    register={register({required:"TTILE REQUIRED"})}
                    name="title"
                    label="title"
                    placeholder="Enter title (required)"
                    error={errors.title}
                />
                <FormInput
                    type="text"
                    register={register({required:"AUTHOR REQUIRED"})}
                    name="author"
                    label="Author"
                    placeholder="Enter author (required)"
                    error={errors.author}
                />
                <FormInput  
                    type="text"
                    register={register({required:"DESCRIPTION REQUIRED"})}
                    name="description"
                    label="Description"
                    placeholder="Enter description (required)"
                    error={errors.description}
                />
                <FormInput
                    type="text"
                    register={register}
                    name="instructions"
                    label="Instructions"
                    placeholder="Enter instructions"
                    error={errors.instructions}
                />
            </div>

            
            <div className="w-1/2 ml-10 space-y-10 ADDITIONAL">
            <label className="text-2xl text-teal-900">Additional Bundle Information</label>
                <FormInput
                        type="text"
                        register={register}
                        name="slides"
                        label="slides"
                        placeholder="URL for slides"
                        error={errors.slides}
                    />
                <FormInput
                    type="text"
                    register={register}
                    name="git_link"
                    label="git"
                    placeholder="GitHub link"
                    error={errors.git_link}
                />
                <div className="ProductsSection">
                <label className="text-teal-900">Products:</label>
                
                <Controller
                    control={control}
                    name="products"
                    render={({ setValue, selectedOption, value }) => (
                    <Select
                        ref={register}
                        defaultValue={[ProductOptions[1], ProductOptions[2]]}
                        isMulti
                        name="products"
                        value={selectedOption}
                        options={ProductOptions}
                        onChange={handleMultiChange}
                        className="w-full p-2 outline-none basic-multi-select"
                        isSearchable
                        setValue={setValue}
                    />
                    )}
                />
                </div>
                <div className="flex DateSection">
                    <label className="w-1/3 text-teal-900"> Date Presented: </label>
                    <label className="flex space-x-2 outline-none">
                    
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 60 60" className="text-teal-700 stroke-current heroicon-calendar heroicon heroicon-sm">
                        <path className="heroicon-calendar-binding heroicon-component-accent heroicon-component-fill" d="M48 3h12v9H0V3h12v3a2 2 0 0 0 2 2 2 2 0 0 0 2-2V3h28v3a2 2 0 0 0 2 2 2 2 0 0 0 2-2V3z"></path>
                        <rect className="heroicon-calendar-date heroicon-component-accent heroicon-component-fill" width="8" height="7" x="40" y="30"></rect>
                        <path className="heroicon-outline" fillRule="nonzero" d="M14 0a2 2 0 0 1 2 2v1h28V2a2 2 0 1 1 4 0v1h12v57H0V3h12V2c0-1.1.9-2 2-2zm1 6V2a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0zm1-1v1a2 2 0 1 1-4 0V5H2v4h56V5H48v1a2 2 0 1 1-4 0V5H16zm31 1V2a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0zm11 45V13H2v38h56zm0-40v-1H2v1h56zM2 56h20v1H2v1h56v-1H47v-1h11v-1H39v-1h19v-1H2v1h15v1H2v1zm11-38v6h6v-6h1v6h6v-6h1v6h6v-6h1v6h6v-6h1v6h6v-6h1v6h8v1h-8v5h8v1h-8v5h8v1h-8v5h8v1h-8v6h-1v-6h-6v6h-1v-6h-6v6h-1v-6h-6v6h-1v-6h-6v6h-1v-6h-6v6h-1v-6H4v-1h8v-5H4v-1h8v-5H4v-1h8v-5H4v-1h8v-6h1zm34 24v-5h-6v5h6zm-7 0v-5h-6v5h6zm-7 0v-5h-6v5h6zm-7 0v-5h-6v5h6zm-7 0v-5h-6v5h6zm28-6v-5h-6v5h6zm-7 0v-5h-6v5h6zm-7 0v-5h-6v5h6zm-7 0v-5h-6v5h6zm-7 0v-5h-6v5h6zm28-6v-5h-6v5h6zm-7 0v-5h-6v5h6zm-7 0v-5h-6v5h6zm-7 0v-5h-6v5h6zm-7 0v-5h-6v5h6zm23 8h1v1h-1v-1zm-6 0v1h-1v-1h1zm-7 0v1h-1v-1h1zm-7 0v1h-1v-1h1zm-7 0v1h-1v-1h1zm28-6v1h-1v-1h1zm-7 0v1h-1v-1h1zm-7 0v1h-1v-1h1zm-7 0v1h-1v-1h1zm-7 0v1h-1v-1h1zm28-6v1h-1v-1h1zm-7 0v1h-1v-1h1zm-7 0v1h-1v-1h1zm-7 0v1h-1v-1h1zm-7 0v1h-1v-1h1zm20-6h1v1h-1v-1zm7 0h1v1h-1v-1zm7 0h1v1h-1v-1zM7 26h1v1H7v-1zm43 0v1h-1v-1h1zM8 32v1H7v-1h1zm41 0h1v1h-1v-1zM7 38h1v1H7v-1zm43 0v1h-1v-1h1zM8 44v1H7v-1h1zm7 0v1h-1v-1h1zm7 0v1h-1v-1h1zm7 0v1h-1v-1h1zm7 0v1h-1v-1h1zm7 0v1h-1v-1h1zm7 0v1h-1v-1h1z"></path>
                    </svg>
                    
                    <Controller
                    className="text-teal-700 outline-none "
                        control={control}
                        name="date_presented"
                        defaultValue={today}
                        render={({ onChange, value }) => (
                        <DatePicker
                            className="outline-none"
                            selected={value}
                            placeholderText="Select date"
                            onChange={onChange}
                        />
                        )}
                    />

                    </label>
                </div>
        </div>   
            </div> 
       
            <hr className="mx-20" style={{
                color: 'indigo',
                backgroundColor: 'indigo',
                height: 1,
                borderColor : 'indigo'
            }}/>     
            <br></br>
            <div className="flex justify-around MEDIAROW ">
            <div className="w-1/4 media-type">
                <div className="flex justify-center media-header">
                    <img className="w-16" src={BlogIcon} alt="blogs" />           
                    <h2 className="my-auto ml-4 font-bold">Related Blogs</h2>
                </div>
                
                <button type="button" className="flex px-4 py-2 mx-auto mt-4 text-white uppercase rounded bg-tolopea-400"
                    onClick={()=>blogAppend({})}>Add Blog +
                </button>
                {blogFields.map(({id, name, url, guest}, index)=>{
                    return (
                        <div key={id} className="flex flex-col py-4 mx-auto my-2 border border-gray-700">
                        <div className="flex flex-col w-full px-10 my-6 space-y-4">
                                <input type="text" className="text-sm border-b border-gray-400 outline-none focus:border-tolopea-400" placeholder={`Enter Blog Title`} name={`blogs[${index}].name`} defaultValue="" ref={register()} />
                                <input type="text" className="text-sm border-b border-gray-400 outline-none focus:border-tolopea-400" placeholder="Enter URL" name={`blogs[${index}].url`} defaultValue="" ref={register()}/>
                                <input type="text" className="text-sm border-b border-gray-400 outline-none focus:border-tolopea-400" placeholder="Enter Guest(s) or Co-Author(s)" name={`blogs[${index}].guest`} defaultValue="" ref={register()}/>
                            </div>
                           <div className="flex justify-evenly buttons">
                               <button type="button" className="px-3 py-1 text-3xl text-white bg-blue-700 rounded" onClick={()=>blogAppend({})}>+</button>
                               <button type="button" className="px-3 py-1 text-3xl text-white bg-red-700 rounded" onClick={()=>blogRemove(index)}>-</button>
                           </div>     
                       </div>
                   );  
                  })} 
                
             
            </div>
                
            <div className="w-1/4 media-type">
                <div className="flex justify-center media-header">
                    <img className="w-16" src={VideoIcon} alt="blogs" /> 
                    <h2 className="my-auto ml-4 font-bold">Related Videos</h2>
                </div>
                
                <button type="button" className="flex px-4 py-2 mx-auto mt-4 text-white uppercase rounded bg-tolopea-400"
                    onClick={()=>videoAppend({})}>Add Video +
                </button>
                {videoFields.map(({id, name, url, guest}, index)=>{
                    return (
                        <div key={id} className="flex flex-col py-4 mx-auto my-2 border border-indigo-700">
                        <div className="flex flex-col w-full px-10 my-6 space-y-4">
                                <input type="text" placeholder={`Enter Video Title`} className="text-sm border-b border-gray-400 outline-none focus:border-tolopea-400" name={`videos[${index}].name`} defaultValue="" ref={register()} />
                                <input type="text" placeholder="Enter URL" className="text-sm border-b border-gray-400 outline-none focus:border-tolopea-400" name={`videos[${index}].url`} defaultValue="" ref={register()}/>
                                <input type="text" placeholder="Enter Guest(s) or Co-Author(s)" className="text-sm border-b border-gray-400 outline-none focus:border-tolopea-400" name={`videos[${index}].guest`} defaultValue="" ref={register()}/>
                            </div>
                            <div className="flex justify-evenly buttons">
                               <button type="button" className="px-3 py-1 text-3xl text-white bg-blue-700 rounded" onClick={()=>videoAppend({})}>+</button>
                               <button type="button" className="px-3 py-1 text-3xl text-white bg-red-700 rounded" onClick={()=>videoRemove(index)}>-</button>
                           </div>     
                       </div>
                      
                   );  
                  })} 
                
                
            </div>
            <div className="w-1/4 media-type">
                <div className="flex justify-center media-header">
                    <img className="w-16" src={PodcastIcon} alt="blogs" />  
                    <h2 className="my-auto ml-4 font-bold">Related Podcasts</h2>
                </div>

                
                <button type="button" className="flex px-4 py-2 mx-auto mt-4 text-white uppercase rounded bg-tolopea-400"
                    onClick={()=>podcastAppend({})}>Add Podcast +
                </button>
                {podcastFields.map(({id, name, url, guest}, index)=>{
                    return (
                        <div key={id} className="flex flex-col py-4 mx-auto my-2 border border-indigo-700">
                            <div className="flex flex-col w-full px-10 my-6 space-y-4">
                                <input type="text" className="text-sm border-b border-gray-400 outline-none focus:border-tolopea-400" placeholder={`Enter Podcast Title`} name={`podcasts[${index}].name`} defaultValue="" ref={register()} />
                                <input type="text" className="text-sm border-b border-gray-400 outline-none focus:border-tolopea-400" placeholder="Enter URL" name={`podcasts[${index}].url`} defaultValue="" ref={register()}/>
                                <input type="text" className="text-sm border-b border-gray-400 outline-none focus:border-tolopea-400" placeholder="Enter Guest(s) or Co-Author(s)" name={`podcasts[${index}].guest`} defaultValue="" ref={register()}/>
                            </div>
                           <div className="flex justify-evenly buttons">
                               <button type="button" className="px-3 py-1 text-3xl text-white bg-blue-700 rounded" onClick={()=>podcastAppend({})}>+</button>
                               <button type="button" className="px-3 py-1 text-3xl text-white bg-red-700 rounded" onClick={()=>podcastRemove(index)}>-</button>
                           </div>     
                       </div>
                   );  
                  })} 
            </div>
        
        </div>
            <input type="submit" className="px-12 py-6 mx-auto mt-6 text-2xl text-white rounded outline-none bg-mongo-500 hover:bg-mongo-600 focus:outline-none"/>
                
        </form>
               
      
        </> 
        : <div className="mt-20 text-4xl text-center font-body text-william-600">Your bundle has been submitted.</div>
    }
   
        </React.Fragment>
                             
    );
                
}
 



export default InputPage;