import React from "react";
import { useParams } from 'react-router-dom';


// HOOK
import { useBundleFetch } from '../hooks/useBundleFetch';
import { EditForm } from '../components/EditForm.js';

const EditBundlePage = () => {
    const { bundleId } = useParams();
    const{ bundleState } = useBundleFetch(bundleId);
    
    return bundleState ? 
    <>
        <div className="flex items-center justify-center bg-gradient-to-r from-green-500 via-mongo-700 to-green-600 h-36">
            <h1 className="text-3xl font-bold text-white uppercase font-body">EDIT {bundleState.title}</h1>
        </div>

        <EditForm 
            preloadedValues={bundleState}
            bundleId={bundleId}
            />
           
    </> : <div>Page isn't working yet. Loading...</div>
}

export default EditBundlePage

 