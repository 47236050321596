import React, {useEffect, useRef, useState} from 'react';
import Select from "react-select";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";

const ProductOptions = [{label: "Any", value: "Any"},
                        {label: "Atlas Search", value: "Atlas Search"},
                        {label: "Atlas", value: "Atlas"},
                        {label: "MongoDB", value: "MongoDB"},
                        {label: "Realm", value: "Realm"},
                        {label: "Charts", value: "Charts"},
                        {label: "Mobile", value: "Mobile"},
                        {label: "Data Lake", value: "Data Lake"},
                        {label: "Compass", value: "Compass"},
                        {label: "Online Archive", value: "Online Archive"},
                        {label: "BI Connector", value: "BI Connector"},
                        {label: "VS Code", value: "VS Code"},
                        {label: "Realm Studio", value: "Realm Studio"}];

const ContentCount = () => {
  const sdk = new ChartsEmbedSDK({baseUrl: 'https://charts.mongodb.com/charts-devhub-czsxe'});
  const refContentCount = useRef(null);
  const [contentCount] = useState(sdk.createChart({chartId: '74fe4adc-8ed2-4f20-b245-ec38c6a39212'}));
  const [prod, setProd] = useState([]);

  useEffect(() => {
    contentCount.render(refContentCount.current).catch(err => console.log('Error while rendering the Chart ContentCount.', err));
  }, [contentCount]);

  useEffect(() => {
    if (prod.value !== "Any") {
      contentCount.setFilter({products: prod.value}).catch(err => console.log(err));
    } else {
      contentCount.setFilter({}).catch(err => console.log(err));
    }
  }, [contentCount, prod]);

  return <div className="w-1/4 h-auto">
    <div className="h-64" id="contentCount" ref={refContentCount}/>

    <div className="flex w-full h-auto outline-none">
      <Select className="w-full p-2 outline-none" options={ProductOptions} onChange={setProd} placeholder="Select Product" isSearchable/>
    </div>
  </div>
}

export default ContentCount
