// THIS IS FOR THE HOME PAGE
import { useState, useEffect } from 'react';
import API from './API.js';


export const useBundlesFetch = () => {
    const[bundles, setBundles] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const fetchBundles = async (searchTerm='')=>{
        try{
            const bundleResponse = await API.fetchBundles(searchTerm);
            setBundles(bundleResponse);
        }
        catch(error){
            console.log(error);
        }

    };          // END OF FUNCTION DEFINTION

    // initial render and search with 1 page calling the fetchContent function above
    useEffect(()=>{
        setBundles([]);
        fetchBundles(searchTerm);
    }, [searchTerm])

    return {
        bundles,
        setSearchTerm,
        searchTerm
    };

}

