import React from 'react'
import BundleCard from '../components/BundleCard';
import SearchBar from '../components/SearchBar';

//HOOK
import { useBundlesFetch } from '../hooks/useBundlesFetch.js';

const HomePage = () => {

    const{ 
        bundles,
        setSearchTerm, 
        searchTerm 
    } = useBundlesFetch();

    return (
        <>
            <div className="flex items-center justify-center bg-gradient-to-r from-tolopea-400 to-tolopea-800 h-36">
                <h1 className="text-4xl font-bold text-white font-body">Developer Bundles</h1>
            </div>
            <div className="container p-20 py-5 mx-auto my-10">
                <SearchBar 
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    page="home"
                />
               { searchTerm && 
                    <div className="flex items-center m-10 space-x-10">
                        <h1 className="text-2xl">Search Results:</h1>
                        <h1 className="mr-10 text-4xl font-bold text-mongo-500">{searchTerm}</h1>
                    </div> 
                }
                <div className="grid grid-cols-3 gap-6 p-2 mt-10 md:grid-cols-4 md:gap-6">
                    {bundles.map(bundle =>(
                        <BundleCard className=""
                            key={bundle._id}
                            title={bundle.title}
                            author={bundle.author}
                            description={bundle.description}
                            bundle_items={bundle.bundle_items}
                            clickable
                            bundleId={bundle._id} 
                        >
                            
                        </BundleCard>
                    ))}
                </div>
            
            </div>
        </>
    )
}

export default HomePage
