import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import {useForm, Controller, useFieldArray } from "react-hook-form";
import Select from "react-select";
import DatePicker from "react-datepicker";
import axios from "axios";

import FormInput from './form-components/FormInput';

import PodcastIcon from "../images/microphone_icon.png";
import VideoIcon from "../images/video_icon.png";
import BlogIcon from "../images/blog_icon.png";
import DatabaseIcon from "../images/Database.png";
import "react-datepicker/dist/react-datepicker.css";


const ProductOptions = [ 
    {label: "Atlas Search", value: "Atlas Search"}, 
    {label: "Atlas", value: "Atlas"},
    {label: "MongoDB", value: "MongoDB"},
    {label: "Realm", value: "Realm"},
    {label: "Charts", value: "Charts"},
    {label: "Mobile", value: "Mobile"},
    {label: "Data Lake", value: "Data Lake"},
    {label: "Compass", value: "Compass"},
    {label: "Online Archive", value: "Online Archive"},
    {label: "BI Connector", value: "BI Connector"},
    {label: "VS Code", value: "VS Code"},
    {label: "Realm Studio", value: "Realm Studio"}
];

export function EditForm({preloadedValues, bundleId}) {

    const defaultValues = preloadedValues;
   
    const today = new Date();
    defaultValues.date_presented = today;

    const [submitted, setSubmitted] = useState(false);
    const [bundleState, setBundleState] = useState({});
    const [valid, setValid] = useState(false);
    const history = useHistory();
    const { register, handleSubmit, errors, control, setValue, reset } = useForm(defaultValues );
    

    const { fields: blogFields, append: blogAppend, remove: blogRemove } = useFieldArray({
        control,
        name:'blogs'
    });
    const { fields:videoFields, append:videoAppend, remove:videoRemove } = useFieldArray({
        control,
        name:'videos'
    });
    const { fields:podcastFields, append:podcastAppend, remove: podcastRemove } = useFieldArray({
        control,
        name:'podcasts'
    });

    useEffect(()=>{
        if (submitted===false) return;
        console.log("BUNDLE STATE");
        console.log(bundleState);
        axios
        .post(
            `https://webhooks.mongodb-realm.com/api/client/v2.0/app/da_share-khckc/service/da_content/incoming_webhook/updateBundle?id=${bundleId}`,
            bundleState
        ).then((res) => {
            console.log("RESPONSE AFTER POST");
            if (res.data.OK === true){
                setValid(true);
                console.log("Response is OK.");
            } else {
                console.log("OOPS! Have Karen check the logs.");
           }
       });   
        setSubmitted(false);  
    //eslint-disable-next-line
    },[submitted]);


  const onSubmit = (data) => {
    console.log("Click Submitting Bundle Data!");
    console.log(data);
    data.owner = defaultValues.owner;

    setBundleState(data);
    setSubmitted(true);
  };

 
  const handleMultiChange = selectedOption => {    
    let prodArray = [];
    for (let i = 0; i < selectedOption.length; i++){
        prodArray.push(selectedOption[i].value)
    }
    setValue("products", prodArray);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
    { valid ?  
        <div className="p-4 mb-8 text-xl text-center bg-yellow-300 success-message">Your bundle has been updated! 🙌🏼 </div>
        :
    
    <div className="flex justify-center">
        <button type="button" 
            className="block w-1/2 h-24 py-3 mt-6 text-4xl font-bold text-white uppercase shadow bg-tolopea-400 rounded-xl focus:outline-none hover:bg-tolopea-200"
            onClick={() => reset(defaultValues)}  
            >Press to Load Existing Bundle Data
        </button>
        <img className="float-right object-scale-down object-right h-36" src={DatabaseIcon} alt="blogs" />
       
      </div>
    }
   
    <div className="flex px-20 py-10 space-x-10 top-row">
   
                
    <div className="flex flex-col w-1/2 pr-10 space-y-10 BASIC">
        <label className="text-2xl text-teal-900">Basic Bundle Information</label>

        <FormInput
            type="text"
            register={register({required:"TITLE REQUIRED"})}
            placeholder="Enter title (required)"
            name="title"
            error={errors.title}
        /> 
        <FormInput
            type="text"
            register={register({required:"AUTHOR REQUIRED"})}
            name="author"
            label="Author"
            placeholder="Enter author (required)"
            error={errors.author}
        />
        <FormInput  
            type="text"
            register={register({required:"DESCRIPTION REQUIRED"})}
            name="description"
            label="Description"
            placeholder="Enter description (required)"
            error={errors.description}
        />
        <FormInput
            type="text"
            register={register}
            name="instructions"
            label="Instructions"
           
            placeholder="Enter instructions"
            error={errors.instructions}
        />
    </div>
    <div className="w-1/2 ml-10 space-y-10 ADDITIONAL">
    <label className="text-2xl text-teal-900">Additional Bundle Information</label>
    <FormInput
            type="text"
            register={register}
            name="slides"
            label="slides"  
            placeholder="URL for slides"
            error={errors.slides}
        />
    <FormInput
        type="text"
        register={register}
        name="git_link"  
        label="git"
        placeholder="GitHub link"
        error={errors.git_link}
    />
    <div className="ProductsSection">
    <label className="text-teal-900">Products:</label>
    
    <Controller
        control={control}
        name="products"
        defaultValue={[ProductOptions[1], ProductOptions[2]]}
        render={({ setValue, selectedOption, value }) => (
        <Select
            ref={register}
            defaultValue={[ProductOptions[1], ProductOptions[2]]}
            isMulti
            name="products"
            value={selectedOption}
            options={ProductOptions}
            onChange={handleMultiChange}
            className="w-full p-2 outline-none basic-multi-select"
            isSearchable
            setValue={setValue}
        />
        )}
    />
    </div>
    <div className="flex DateSection">
        <label className="w-1/3 text-teal-900"> Date Presented: </label>
        <label className="flex space-x-2 outline-none">
        
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 60 60" className="text-teal-700 stroke-current heroicon-calendar heroicon heroicon-sm">
            <path className="heroicon-calendar-binding heroicon-component-accent heroicon-component-fill" d="M48 3h12v9H0V3h12v3a2 2 0 0 0 2 2 2 2 0 0 0 2-2V3h28v3a2 2 0 0 0 2 2 2 2 0 0 0 2-2V3z"></path>
            <rect className="heroicon-calendar-date heroicon-component-accent heroicon-component-fill" width="8" height="7" x="40" y="30"></rect>
            <path className="heroicon-outline" fillRule="nonzero" d="M14 0a2 2 0 0 1 2 2v1h28V2a2 2 0 1 1 4 0v1h12v57H0V3h12V2c0-1.1.9-2 2-2zm1 6V2a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0zm1-1v1a2 2 0 1 1-4 0V5H2v4h56V5H48v1a2 2 0 1 1-4 0V5H16zm31 1V2a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0zm11 45V13H2v38h56zm0-40v-1H2v1h56zM2 56h20v1H2v1h56v-1H47v-1h11v-1H39v-1h19v-1H2v1h15v1H2v1zm11-38v6h6v-6h1v6h6v-6h1v6h6v-6h1v6h6v-6h1v6h6v-6h1v6h8v1h-8v5h8v1h-8v5h8v1h-8v5h8v1h-8v6h-1v-6h-6v6h-1v-6h-6v6h-1v-6h-6v6h-1v-6h-6v6h-1v-6h-6v6h-1v-6H4v-1h8v-5H4v-1h8v-5H4v-1h8v-5H4v-1h8v-6h1zm34 24v-5h-6v5h6zm-7 0v-5h-6v5h6zm-7 0v-5h-6v5h6zm-7 0v-5h-6v5h6zm-7 0v-5h-6v5h6zm28-6v-5h-6v5h6zm-7 0v-5h-6v5h6zm-7 0v-5h-6v5h6zm-7 0v-5h-6v5h6zm-7 0v-5h-6v5h6zm28-6v-5h-6v5h6zm-7 0v-5h-6v5h6zm-7 0v-5h-6v5h6zm-7 0v-5h-6v5h6zm-7 0v-5h-6v5h6zm23 8h1v1h-1v-1zm-6 0v1h-1v-1h1zm-7 0v1h-1v-1h1zm-7 0v1h-1v-1h1zm-7 0v1h-1v-1h1zm28-6v1h-1v-1h1zm-7 0v1h-1v-1h1zm-7 0v1h-1v-1h1zm-7 0v1h-1v-1h1zm-7 0v1h-1v-1h1zm28-6v1h-1v-1h1zm-7 0v1h-1v-1h1zm-7 0v1h-1v-1h1zm-7 0v1h-1v-1h1zm-7 0v1h-1v-1h1zm20-6h1v1h-1v-1zm7 0h1v1h-1v-1zm7 0h1v1h-1v-1zM7 26h1v1H7v-1zm43 0v1h-1v-1h1zM8 32v1H7v-1h1zm41 0h1v1h-1v-1zM7 38h1v1H7v-1zm43 0v1h-1v-1h1zM8 44v1H7v-1h1zm7 0v1h-1v-1h1zm7 0v1h-1v-1h1zm7 0v1h-1v-1h1zm7 0v1h-1v-1h1zm7 0v1h-1v-1h1zm7 0v1h-1v-1h1z"></path>
        </svg>
        
        <Controller
            className="text-teal-700 outline-none "
            control={control}
            name="date_presented" 
            defaultValue={today}
            render={({ onChange, value }) => (
                <DatePicker
                    className="outline-none"
                    selected={value}   
                    placeholderText="Select date"
                    onChange={onChange}
                />
            )}
        />

    </label>
    </div>
</div>   
</div> 

    <hr className="mx-20" style={{
        color: 'indigo',
        backgroundColor: 'indigo',
        height: 1,
        borderColor : 'indigo'
    }}/>     
    <br></br>
    
    <div className="flex justify-around MEDIAROW ">
    <div className="w-1/4 media-type">
        <div className="flex justify-center media-header">
            <img className="w-16" src={BlogIcon} alt="blogs" />           
            <h2 className="my-auto ml-4 font-bold">Related Blogs</h2>
        </div>
        <button type="button" className="flex px-4 py-2 mx-auto mt-4 text-white uppercase rounded bg-tolopea-400"
            onClick={()=>blogAppend({})}>Add Blog +
        </button>
        {   blogFields ? blogFields.map((blog, index)=>{
            return (   
                <div key={blog.index} className="flex flex-col py-4 mx-auto my-2 border border-gray-700">    
                    <>
                  <div className="flex flex-col w-full px-10 my-6 space-y-4">
                        <input type="text" 
                            className="text-sm border-b border-gray-400 outline-none focus:border-tolopea-400" 
                            placeholder={`Enter Blog Title`} 
                            name={`blogs[${index}].name`} 
                            defaultValue={blog.name}
                            ref={register()} />
                        <input type="text" 
                            className="text-sm border-b border-gray-400 outline-none focus:border-tolopea-400" 
                            placeholder="Enter URL" 
                            name={`blogs[${index}].url`} 
                            defaultValue={blog.url} 
                            ref={register()}/>
                        <input type="text" 
                            className="text-sm border-b border-gray-400 outline-none focus:border-tolopea-400" 
                            placeholder="Enter Guest(s) or Co-Author(s)"
                            name={`blogs[${index}].guest`} 
                            defaultValue={blog.guest} 
                            ref={register()}/>
                       
                    </div>
                    <div className="flex justify-evenly buttons">
                        <button type="button" className="px-3 py-1 text-3xl text-white bg-blue-700 rounded" 
                            onClick={()=>{
                                blogAppend({ });
                                console.log(`Click! ${index}`);
                               
                                }}>+</button>
                        <button type="button" className="px-3 py-1 text-3xl text-white bg-red-700 rounded" 
                            onClick={()=>{
                                blogRemove(index)
                            }}
                        >-</button>
                    </div> 
                    </>
                   
                </div>
           );  
        })
        :
            <button type="button" className="flex px-4 py-2 mx-auto mt-4 text-white uppercase rounded bg-tolopea-400"
                onClick={()=>blogAppend({})}>Add Blog +
            </button>
        }  
    </div>

    <div className="w-1/4 media-type">
        <div className="flex justify-center media-header">
            <img className="w-16" src={VideoIcon} alt="blogs" /> 
            <h2 className="my-auto ml-4 font-bold">Related Videos</h2>
        </div>
        
        <button type="button" className="flex px-4 py-2 mx-auto mt-4 text-white uppercase rounded bg-tolopea-400"
            onClick={()=>videoAppend({})}>Add Video +
        </button>

        {   videoFields ? videoFields.map((video, index)=>{
            return (
                <div key={video.id} className="flex flex-col py-4 mx-auto my-2 border border-gray-700">
                <div className="flex flex-col w-full px-10 my-6 space-y-4">
                        <input type="text" 
                            className="text-sm border-b border-gray-400 outline-none focus:border-tolopea-400" 
                            placeholder={`Enter Video Title`} 
                            name={`videos[${index}].name`} 
                            defaultValue={video.name} 
                            ref={register()} />
                        <input type="text" className="text-sm border-b border-gray-400 outline-none focus:border-tolopea-400" placeholder="Enter URL" name={`videos[${index}].url`} defaultValue={video.url} ref={register()}/>
                        <input type="text" className="text-sm border-b border-gray-400 outline-none focus:border-tolopea-400" placeholder="Enter Guest(s) or Co-Author(s)" name={`videos[${index}].guest`} defaultValue={video.guest} ref={register()}/>
                    </div>
                   <div className="flex justify-evenly buttons">
                       <button type="button" className="px-3 py-1 text-3xl text-white bg-blue-700 rounded" onClick={()=>videoAppend({})}>+</button>
                       <button type="button" className="px-3 py-1 text-3xl text-white bg-red-700 rounded" onClick={()=>videoRemove(index)}>-</button>
                   </div>     
               </div>
           );  
          })
          :
            <button type="button" className="flex px-4 py-2 mx-auto mt-4 text-white uppercase rounded bg-tolopea-400"
                onClick={()=>videoAppend({})}>Add Video +
            </button>
            }    
    </div>
    <div className="w-1/4 media-type">
        <div className="flex justify-center media-header">
            <img className="w-16" src={PodcastIcon} alt="blogs" />  
            <h2 className="my-auto ml-4 font-bold">Related Podcasts</h2>
        </div>

        <button type="button" className="flex px-4 py-2 mx-auto mt-4 text-white uppercase rounded bg-tolopea-400"
            onClick={()=>podcastAppend({})}>Add Podcast +
        </button>
   
        {   podcastFields ? podcastFields.map((podcast, index)=>{
            return (
                <div key={podcast.id} className="flex flex-col py-4 mx-auto my-2 border border-indigo-700">
                    <div className="flex flex-col w-full px-10 my-6 space-y-4">
                        <input type="text" className="text-sm border-b border-gray-400 outline-none focus:border-tolopea-400" placeholder={`Enter Podcast Title`} name={`podcasts[${index}].name`} defaultValue={podcast.name} ref={register()} />
                        <input type="text" className="text-sm border-b border-gray-400 outline-none focus:border-tolopea-400" placeholder="Enter URL" name={`podcasts[${index}].url`} defaultValue={podcast.url} ref={register()}/>
                        <input type="text" className="text-sm border-b border-gray-400 outline-none focus:border-tolopea-400" placeholder="Enter Guest(s) or Co-Author(s)" name={`podcasts[${index}].guest`} defaultValue={podcast.guest} ref={register()}/>
                    </div>
                   <div className="flex justify-evenly buttons">
                       <button type="button" className="px-3 py-1 text-3xl text-white bg-blue-700 rounded" onClick={()=>podcastAppend({})}>+</button>
                       <button type="button" className="px-3 py-1 text-3xl text-white bg-red-700 rounded" onClick={()=>podcastRemove(index)}>-</button>
                   </div>     
               </div>

           );  
          })
          :
            <button type="button" className="flex px-4 py-2 mx-auto mt-4 text-white uppercase rounded bg-tolopea-400"
                onClick={()=>podcastAppend({})}>Add Podcast +
            </button>
            }    
    </div>
        
    
   
   </div>
   { !valid ?  
    <div className="flex justify-center">
        <button  className="block w-full p-4 mt-4 text-xl font-bold text-white rounded shadow bg-mongo-600 focus:outline-none" 
        type="submit">Submit</button>
    </div>
    : 
    <>
        <button 
            type="button" 
            onClick={()=>history.push(`/${bundleId}`)}
            className="block w-full p-4 text-xl font-bold bg-yellow-300 rounded shadow focus:outline-none"
            >{bundleState.title} has been updated!  🎉
        </button>
        <button 
            type="button" 
            onClick={()=>history.push('/')}
            className="block w-full p-4 text-xl font-bold text-white bg-purple-800 rounded shadow focus:outline-none"
            >Home
        </button>
    </>
    } 
    </form>
  );
}
