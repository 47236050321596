import React, {useState, useContext, createContext, useEffect } from 'react'
import {AppContext} from '../App.js';
import API from '../hooks/API';


export const CurrentUserContext =  createContext();

export const useCurrentUser = ()=>{
    const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
    return ({ 
      currentUser : currentUser, 
      setCurrentUser : setCurrentUser
    });
  }

export const CurrentUserProvider =({children})=>{
    const app = useContext(AppContext);   // WHERE I GET APP
 
    const [currentUser, setCurrentUser] = useState(app.currentUser);
    const[bundlers, setBundlers] = useState([]);
    const[supers, setSupers] = useState([]);
   
    const value = {
      currentUser:currentUser,
      setCurrentUser,
      bundlers,
      supers
    }; 
  
    useEffect(()=>{
      console.log("IN CURRENT USER BUNDLERS USE EFFECT");
      
      const fetchBundlers = async ()=>{
        try{
            const bundlersResponse = await API.fetchBundlers();
            setBundlers(bundlersResponse);
          }
        catch(error){
            console.log(error);
        }
      };
        const fetchSupers = async ()=>{
          try{
              const supersResponse = await API.fetchSupers();
              setSupers(supersResponse);
            }
          catch(error){
              console.log(error);
          }
    };          // END OF FUNCTION DEFINTION
      
      if (currentUser){
        console.log(currentUser.profile.email);
        fetchBundlers();
        fetchSupers();
    
      }
      else{
        console.log("NO CURRENT USER");
      }
    
    }, [currentUser]);   
  
    return (
      <CurrentUserContext.Provider
        value={value}
      >{children}
      </CurrentUserContext.Provider>
    );
  
  }
  