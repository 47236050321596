import React, {useEffect, useRef, useState} from 'react';
import Select from "react-select";
import DatePicker from "react-datepicker";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";

const ProductOptions = [{label: "Any", value: "Any"},
                        {label: "Atlas Search", value: "Atlas Search"},
                        {label: "Atlas", value: "Atlas"},
                        {label: "MongoDB", value: "MongoDB"},
                        {label: "Realm", value: "Realm"},
                        {label: "Charts", value: "Charts"},
                        {label: "Mobile", value: "Mobile"},
                        {label: "Data Lake", value: "Data Lake"},
                        {label: "Compass", value: "Compass"},
                        {label: "Online Archive", value: "Online Archive"},
                        {label: "BI Connector", value: "BI Connector"},
                        {label: "VS Code", value: "VS Code"},
                        {label: "Realm Studio", value: "Realm Studio"}];

const AllPublishedBlogs = () => {
  const sdk = new ChartsEmbedSDK({baseUrl: 'https://charts.mongodb.com/charts-devhub-czsxe'});
  const refPubChart = useRef(null);
  const [pubContentChart] = useState(sdk.createChart({chartId: 'e1b274ca-6661-43f3-9692-cef96b88f2f0'}));
  const [startDate, setStartDate] = useState(new Date(2018, 11, 24));
  const [prod, setProd] = useState([]);

  useEffect(() => {
    pubContentChart.render(refPubChart.current).catch(err => console.log('Error while rendering Chart AllPublishedBlogs.', err))
  }, [pubContentChart]);

  useEffect(() => {
    if (prod.value === "Any") {
      pubContentChart.setFilter({publishedDate: {$gte: startDate}}).catch(err => console.log(err))
    } else {
      pubContentChart.setFilter({products: prod.value, publishedDate: {$gte: startDate}}).catch(err => console.log(err))
    }
  }, [prod, startDate]);

  return <>
    <div className="w-full h-96" id="publishedContentChart" ref={refPubChart}/>
    <div id="filters" className="flex w-full px-10 justify-evenly">
      <label className="flex w-1/2">Show Content From:
        <DatePicker className="px-10 mx-10 border rounded outline-none border-tolopea-500" selected={startDate} placeholderText="Select date"
                    onChange={date => setStartDate(date)}/>
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 60 60" className="stroke-current text-tolopea-400 heroicon-calendar heroicon heroicon-sm">
          <path className="heroicon-calendar-binding heroicon-component-accent heroicon-component-fill"
                d="M48 3h12v9H0V3h12v3a2 2 0 0 0 2 2 2 2 0 0 0 2-2V3h28v3a2 2 0 0 0 2 2 2 2 0 0 0 2-2V3z"/>
          <rect className="heroicon-calendar-date heroicon-component-accent heroicon-component-fill" width="8" height="7" x="40" y="30"/>
          <path className="heroicon-outline" fillRule="nonzero"
                d="M14 0a2 2 0 0 1 2 2v1h28V2a2 2 0 1 1 4 0v1h12v57H0V3h12V2c0-1.1.9-2 2-2zm1 6V2a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0zm1-1v1a2 2 0 1 1-4 0V5H2v4h56V5H48v1a2 2 0 1 1-4 0V5H16zm31 1V2a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0zm11 45V13H2v38h56zm0-40v-1H2v1h56zM2 56h20v1H2v1h56v-1H47v-1h11v-1H39v-1h19v-1H2v1h15v1H2v1zm11-38v6h6v-6h1v6h6v-6h1v6h6v-6h1v6h6v-6h1v6h6v-6h1v6h8v1h-8v5h8v1h-8v5h8v1h-8v5h8v1h-8v6h-1v-6h-6v6h-1v-6h-6v6h-1v-6h-6v6h-1v-6h-6v6h-1v-6h-6v6h-1v-6H4v-1h8v-5H4v-1h8v-5H4v-1h8v-5H4v-1h8v-6h1zm34 24v-5h-6v5h6zm-7 0v-5h-6v5h6zm-7 0v-5h-6v5h6zm-7 0v-5h-6v5h6zm-7 0v-5h-6v5h6zm28-6v-5h-6v5h6zm-7 0v-5h-6v5h6zm-7 0v-5h-6v5h6zm-7 0v-5h-6v5h6zm-7 0v-5h-6v5h6zm28-6v-5h-6v5h6zm-7 0v-5h-6v5h6zm-7 0v-5h-6v5h6zm-7 0v-5h-6v5h6zm-7 0v-5h-6v5h6zm23 8h1v1h-1v-1zm-6 0v1h-1v-1h1zm-7 0v1h-1v-1h1zm-7 0v1h-1v-1h1zm-7 0v1h-1v-1h1zm28-6v1h-1v-1h1zm-7 0v1h-1v-1h1zm-7 0v1h-1v-1h1zm-7 0v1h-1v-1h1zm-7 0v1h-1v-1h1zm28-6v1h-1v-1h1zm-7 0v1h-1v-1h1zm-7 0v1h-1v-1h1zm-7 0v1h-1v-1h1zm-7 0v1h-1v-1h1zm20-6h1v1h-1v-1zm7 0h1v1h-1v-1zm7 0h1v1h-1v-1zM7 26h1v1H7v-1zm43 0v1h-1v-1h1zM8 32v1H7v-1h1zm41 0h1v1h-1v-1zM7 38h1v1H7v-1zm43 0v1h-1v-1h1zM8 44v1H7v-1h1zm7 0v1h-1v-1h1zm7 0v1h-1v-1h1zm7 0v1h-1v-1h1zm7 0v1h-1v-1h1zm7 0v1h-1v-1h1zm7 0v1h-1v-1h1z"/>
        </svg>
      </label>
      <div className="flex w-1/2 h-auto outline-none">
        <label className="flex w-full">Select Products:
          <Select className="w-2/3 ml-10 border rounded text-tolopea-600 active:border-tolopea-800" options={ProductOptions} onChange={setProd} placeholder="Select Product"
                  isSearchable/>
        </label>
      </div>
    </div>
  </>
}

export default AllPublishedBlogs
