import React from 'react';
import {useForm } from "react-hook-form";

import SearchIcon from '../images/Search.png';
  

const SearchBar = ({searchTerm, setSearchTerm, page}) => {
    const {register, handleSubmit, reset} = useForm();
   
    const onSubmit = (data) => {
       console.log("DATA SUBMITTED ON SEARCH FORM");
        setSearchTerm(data.term);
        reset();
     //   setValue("term", "");
    }
    return (

        <form onSubmit={handleSubmit(onSubmit)} className="flex">
            <div className="flex justify-between w-1/2 px-6 py-2 mx-auto text-2xl text-black placeholder-green-500 duration-700 ease-in-out transform border border-green-800 rounded-full transition-width hover:shadow-xl hover:w-4/5 active:w-4/5 focus:w-4/5">
                <input 
                    type="text" 
                    name="term"
                    id="search" 
                    placeholder="Search content" 
                    autocomplete="off"
                    ref={register}
                    className="flex-grow w-full px-10 outline-none"
                ></input>
                <img onClick={onSubmit} src={SearchIcon} alt="magnifier" className="w-20 transform -rotate-12"/>   
            </div>
        </form>
    )
}

export default SearchBar
