import React from 'react'

const FormInput = ({register, error, name, label, type, placeholder, ...inputProps}) => {
    return (
        <>
        <input
            className="block w-full border-b border-gray-700 outline-none focus:outline-none"
            name={name} 
            type={type}
            placeholder={placeholder}
            label={label}
            ref={register}
            {...inputProps}
            error={error}
        />
        {error && <p className="text-pink-700">{error.message}</p>}
            
        </>
    )
}

export default FormInput
