import React from "react";
import { useHistory } from "react-router-dom";

import Lock from "../images/Lock.png";
import UserAuth from "../images/UserAuth.png";

import { useApp } from "../App.js";

import { useCurrentUser } from "../components/CurrentUserContext.js";

import * as Realm from "realm-web";
// useApp is my context consumer

// SWAP ON LINE 46 when needed:
//await app.logIn(Realm.Credentials.google(`http://localhost:3000/confirm`));
// await app.logIn(Realm.Credentials.google(`https://da-share-khckc-bnmli.mongodbstitch.com/confirm`));

const SignInPage = () => {
  const app = useApp();
  const { currentUser, setCurrentUser } = useCurrentUser();
  const history = useHistory();

  return (
    <>
      <div className="flex items-center justify-center mb-20 bg-gradient-to-r from-purple-600 to-purple-900 h-36">
        <h1 className="text-3xl font-bold text-white font-body">
          Welcome to the Developer Advocacy Bundles
        </h1>
      </div>
      <div className="container flex p-10 mx-auto shadow-2xl">
        <div className="w-1/3">
          <img className="w-full mx-auto" src={Lock} alt="User" />
        </div>
        <div className="px-20 text-center font-body">
          {currentUser ? (
            <h1 className="text-4xl text-green-700">
              Did you know we have a podcast?{" "}
            </h1>
          ) : (
            <>
              <h1 className="text-4xl text-green-700">
                Did you know we have a podcast?{" "}
              </h1>
              <br></br>
              <h1 className="text-2xl">
                Here you can find and upload all related blogs, videos, git
                repos, and other MongoDB content items as "Bundles." After all,
                data that is accessed together should be stored together.
              </h1>
              <br></br>
              <h1 className="text-2xl">
                Sign in with your MongoDB Google account to check out the
                content bundles.
              </h1>
              <div className="flex flex-col items-center mt-4">
                <img className="w-36" src={UserAuth} alt="User" />
                <button
                  className="px-6 py-4 text-xl text-white transition duration-300 bg-purple-700 rounded-lg shadow-xl focus:outline-none focus:shadow-outline hover:bg-purple-400 hover:text-purple-900"
                  onClick={async () => {
                    await app.logIn(
                      Realm.Credentials.google(
                        `https://da-share-khckc-bnmli.mongodbstitch.com/confirm`
                      )
                    );
                    setCurrentUser(app.currentUser);
                    history.push("/");
                  }}
                >
                  Sign In with Google
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SignInPage;
