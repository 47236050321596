import React, { useState, useContext, useEffect} from 'react';
import { useHistory, Link } from 'react-router-dom';
import AuthorCard from '../components/AuthorCard';
import {CurrentUserContext} from '../components/CurrentUserContext';
import SearchBar from '../components/SearchBar';
import axios from "axios";

const BundlersPage = () => {
    const [searchTerm, setSearchTerm] = useState('');

    const { currentUser, setCurrentUser, bundlers, supers} = useContext(CurrentUserContext);

    const [authorState, setAuthorState] = useState([])
   
    const history = useHistory(); 
    let ENABLESUPER = false;
    
    if (currentUser){
        
        // console.log("IN BUNDLERS PAGE");
        // console.log("Supers", supers);
        ENABLESUPER = supers.includes(currentUser.profile.email.toLowerCase());
       // console.log(currentUser.profile.email); 
       
        console.log("ENABLESUPER", ENABLESUPER);
        
    }
    
useEffect(()=>{
    const fetchAuthors = async() =>{
        const endpoint=`https://webhooks.mongodb-realm.com/api/client/v2.0/app/da_share-khckc/service/search/incoming_webhook/search-bundlers-fuzzy?arg=${searchTerm}`
        const {data} = await axios.get(endpoint);
        setAuthorState(data);
    }
    fetchAuthors();
    
}, [searchTerm])
    
    return (
        <>
        <div className="flex items-center justify-center bg-gradient-to-r from-ultramarine-400 via-tolopea-600 to-ultramarine-700 h-36">
            <h1 className="text-3xl font-bold text-white font-body">Meet the Bundlers</h1>
        </div>
        
        <div className="container p-20 py-5 mx-auto my-10">
        <div className="flex w-full justify-evenly">
                <div className="flex-grow">
                    <SearchBar
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        onChange={setSearchTerm}
                        page="bundlers"
                    />
                </div>
                {   ENABLESUPER ? 
                    <div className="my-auto">
                        <button onClick={()=>history.push('/updateAuthor/6050d50958ae174c67c35c45')}
                            className="flex-grow-0 h-24 px-6 py-3 ml-5 text-2xl text-white bg-purple-900 rounded-lg shadow-md focus:outline-none hover:shadow-2xl hover:bg-purple-700"  
                        >✨  Add Bundler 👩🏽‍💻</button>  
                    </div>
                    : null
                }
            </div>
       { searchTerm && 
            <div className="flex items-center m-10 space-x-10">
                <h1 className="text-2xl">Bundler:</h1>
                <h1 className="mr-10 text-4xl font-bold text-mongo-500">{searchTerm}</h1>
            </div> 
        }
        

        <div className="grid grid-cols-3 gap-6 p-2 mt-10 md:grid-cols-4 md:gap-6">
        {   authorState.map(author =>(
            <AuthorCard
                key={author._id}
                name={author.name}
                email={author.email}
                title={author.title}
                image={author.image}
                clickable
                authorId={author._id} 
                bundler={author.bundler}
                twitter={author.twitter}
            ></AuthorCard>
            
        ))}
        
               
        </div>
        </div>
       
</>
    )
}

export default BundlersPage





