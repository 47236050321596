import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { handleAuthRedirect} from 'realm-web';
//import {useCurrentUser} from '../components/CurrentUserContext.js';
import Developer from '../images/developer.png';
import RealmHero from '../images/RealmHero.png';

const ConfirmPage = () => {
   // const { currentUser } = useCurrentUser();
    const history = useHistory();

    useEffect(()=>{
        handleAuthRedirect();
    }, []);
    return (
    <>
    <div className="flex items-center justify-center mb-20 bg-gradient-to-r from-purple-600 to-purple-900 h-36">
        <h1 className="text-3xl font-bold text-white font-body">Welcome to MongoDB Bundles</h1>
    </div>
    <div className="container flex h-auto p-10 mx-auto shadow-2xl">
         
        <img className="w-1/3 h-auto mx-auto" src={Developer} alt="Developer" />
        
        <div className="px-10 pt-10 text-center font-body">
            <h1 className="text-4xl text-green-700">Click button below to explore MongoDB bundled content.</h1>
            <br></br>
            <div className="flex flex-col items-center mt-4">
               
                <button className="px-6 py-4 text-xl text-white transition duration-300 bg-purple-700 rounded-lg shadow-xl focus:outline-none focus:shadow-outline hover:bg-purple-400 hover:text-purple-900"
                onClick={()=>{
                    history.push('/');
                }} 
                >Check Out the Bundles
                </button>
            </div>
        </div>
        <img className="w-1/3 h-auto mx-auto" src={RealmHero} alt="Realm" />
    </div> 
    </>
    )
}

export default ConfirmPage;

