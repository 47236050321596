import React, {useEffect, useRef, useState} from 'react';
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";

const YoutubeChart = (props) => {
  const sdk = new ChartsEmbedSDK({baseUrl: 'https://charts.mongodb.com/charts-devhub-czsxe'});
  const chartDiv = useRef(null);
  const [chart] = useState(sdk.createChart({chartId: props.chartId, height: props.height, width: props.width, theme: "light"}));

  useEffect(() => {
    chart.render(chartDiv.current).catch(err => console.log("Error during Charts rendering.", err));
  }, []);

  return <div ref={chartDiv}/>;
};

export default YoutubeChart;
