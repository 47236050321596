import React, {useContext, useEffect, useRef, useState} from 'react';
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import {AppContext} from "../../App";

const YoutubeChart = (props) => {
  const app = useContext(AppContext);
  const sdk = new ChartsEmbedSDK({baseUrl: 'https://charts.mongodb.com/charts-devhub-czsxe', getUserToken: () => app.currentUser.accessToken});
  const chartDiv = useRef(null);
  const [chart] = useState(sdk.createChart({chartId: props.chartId, height: props.height, width: props.width, theme: "dark"}));
  const filter = props.filter;

  useEffect(() => {
    chart.render(chartDiv.current).catch(err => console.log("Error during Charts rendering.", err));
  }, []);

  useEffect(() => {
    chart.setFilter(filter).catch(err => console.log("Error while filtering", err));
  }, [filter]);

  return <div className="m-1 border border-mongo-500" ref={chartDiv}/>;
};

export default YoutubeChart;
