import React from 'react';
import {NavLink} from "react-router-dom";

const NavCharts = () => {
  return <div className="flex justify-center">
    <NavLink to="./devhub"
             className="p-4 m-4 font-mono text-xl font-bold tracking-wider uppercase border-2 rounded-full cursor-pointer border-mongo-800 hover:bg-teal-800 hover:text-white hover:shadow-lg"
             activeClassName="bg-green-600 focus:border-current text-white disable">
      DevHub Charts
    </NavLink>
    <NavLink to="./youtube"
             className="p-4 m-4 font-mono text-xl font-bold tracking-wider uppercase border-2 rounded-full cursor-pointer border-mongo-800 hover:bg-green-800 hover:text-white hover:shadow-lg"
             activeClassName="bg-green-600 focus:border-current text-white disable">
      Youtube Charts
    </NavLink>
  </div>;
}

export default NavCharts;
