  // below is an object with different functions and their names
 
  
  const apiSettings = {
    
    // this creates my URL for the fetch and gets data from it.

    fetchBundlers: async()=>{
      const endpoint = 
        `https://webhooks.mongodb-realm.com/api/client/v2.0/app/da_share-khckc/service/search/incoming_webhook/get-all-bundlers-emails?status=bundler`;
      return await (await fetch(endpoint)).json();
    },
    fetchSupers: async()=>{
      const endpoint = 
      `https://webhooks.mongodb-realm.com/api/client/v2.0/app/da_share-khckc/service/search/incoming_webhook/get-all-bundlers-emails?status=super`;
      return await (await fetch(endpoint)).json();
    },
   
    fetchBundle: async bundleId => {
      const endpoint = 
       `https://webhooks.mongodb-realm.com/api/client/v2.0/app/da_share-khckc/service/search/incoming_webhook/get-bundle-by-id?id=${bundleId}`;
      return await fetch(endpoint);
    },

    fetchAuthor: async authorId => {
      const endpoint = 
       `https://webhooks.mongodb-realm.com/api/client/v2.0/app/da_share-khckc/service/search/incoming_webhook/get-author-by-id?id=${authorId}`;
      return await (await fetch(endpoint)).json();
    },

    fetchBundles: async (searchTerm) => {
      const endpoint = searchTerm!==''
        ? `https://webhooks.mongodb-realm.com/api/client/v2.0/app/da_share-khckc/service/search/incoming_webhook/search-bundles-fuzzy?arg=${searchTerm}`
        : `https://webhooks.mongodb-realm.com/api/client/v2.0/app/da_share-khckc/service/search/incoming_webhook/search-bundles-fuzzy`
       // console.log("ENDPOINT: " + endpoint);
      return await (await fetch(endpoint)).json();
    },

    fetchBlogs: async (searchTerm, page, prods) => {
      const endpoint = 
         `https://webhooks.mongodb-realm.com/api/client/v2.0/app/da_share-khckc/service/search/incoming_webhook/search-blogs-fuzzy?arg=${searchTerm}&page=${page}&prods=${prods}`;
        console.log("ENDPOINT: " + endpoint);
      return await (await fetch(endpoint)).json();
    }
    
    
  };
  
  export default apiSettings;
  