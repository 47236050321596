import React from 'react';
import { Link } from 'react-router-dom';
import TwitterIcon from '../images/twitter_icon.png';
import Dev from '../images/dev.png'

const AuthorCard = ({name, email, title, image, twitter, authorId}) => {
    return (
        
        <Link to={`/author/${authorId}`} className="p-8 space-y-2 text-center transition duration-500 transform border border-blue-800 rounded-lg shadow-lg hover:scale-105 font-body"> 
                <h1 className="text-3xl text-bold">{name}</h1>
                {   image !=="" ? <img className="w-1/2 mx-auto rounded-lg shadow-2xl" src={image} alt="advocate" />  
                : <img className="w-1/2 mx-auto rounded-lg shadow-2xl" src={Dev} alt="advocate" /> 
                }
                <h1 className="text-xl mt-50 text-bold text-tolopea-500">{title}</h1>    
                <h1>{email}</h1>
                <div className="flex items-center justify-center space-x-4">
                    <span>
                        <img className="w-8 mx-auto" src={TwitterIcon} alt="twitter" />
                    </span>
                    <span >{twitter}</span>   
                </div>
            </Link>
       
    )
}

export default AuthorCard
