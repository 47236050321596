import { useState, useEffect } from 'react';


export const useAuthorFetch = (authorId) => {
    const [authorState, setAuthorState]= useState({});
    const [bundleState, setBundleState]= useState([]);
   
    const fetchAuthor= async(authorId) =>{
        
    console.log("In fetchAuthor");
    console.log(authorId);
    
    try {
        let authorResponse = await(await fetch(`https://webhooks.mongodb-realm.com/api/client/v2.0/app/da_share-khckc/service/search/incoming_webhook/getAuthor?id=${authorId}`)).json();
        console.log("author response");
        console.log(authorResponse);
        setAuthorState(authorResponse); 
        let author = authorResponse.name;   
        
        let bundleResponse = await(await fetch(`https://webhooks.mongodb-realm.com/api/client/v2.0/app/da_share-khckc/service/search/incoming_webhook/get-bundle-by-id?author=${author}`)).json();            
        console.log("AUTHOR BUNDLES");
        console.log(bundleResponse);
        setBundleState(bundleResponse);      
    
        }
        catch (err){
            console.log(err);
        }
    }

   

    useEffect(()=>{
        fetchAuthor(authorId);
       
    //eslint-disable-next-line   
    },[]);

    return { authorState, bundleState };
}
