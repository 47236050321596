import { useState, useEffect } from 'react';
import axios from "axios"; 

export const useBundleFetch = (bundleId) => {
    const [bundleState,setBundleState] = useState({});
    const [authorState, setAuthorState]= useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
   

    const fetchBundle= async(bundleId) =>{
        
        setLoading(true);
        setError(false);
        
        console.log("In fetchBundle");
        console.log(bundleId);
        
          try {
            let bundle = await axios.get(`https://webhooks.mongodb-realm.com/api/client/v2.0/app/da_share-khckc/service/search/incoming_webhook/get-bundle-by-id?id=${bundleId}`);
                setBundleState(bundle.data);
                let author = bundle.data.author
                let owner = bundle.data.owner.toLowerCase();

                let authorBundle = await(await fetch(`https://webhooks.mongodb-realm.com/api/client/v2.0/app/da_share-khckc/service/search/incoming_webhook/getAuthor?author=${author}`)).json();
                // console.log(authorBundle);
                // if (!authorBundle){
                //     authorBundle = await(await fetch(`https://webhooks.mongodb-realm.com/api/client/v2.0/app/da_share-khckc/service/search/incoming_webhook/getAuthor?author=${owner}`)).json();
                // }

                setAuthorState(authorBundle);     
            
          }
        catch (err){
            console.log(err);
        }

    } 

    useEffect(()=>{
        fetchBundle(bundleId);
        console.log(authorState);
        console.log("BUNDLED RETURN");
        console.log(typeof(bundleState));
        console.log(bundleState);
       
    //eslint-disable-next-line   
    },[]);

return { bundleState, authorState, loading, error };
}
