import React from "react";

const Pagination = ({ maxPages, setCurrentPage, currentPage }) => {
  const pageNumbers = [];

  console.log("IN PAGINATION");
  let n = Object.values(maxPages);
  let numPages = n[0];

  for (let i = 1; i <= numPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className="mt-10">
      <ul className="flex justify-center text-2xl flex-wrap space-y-2">
        {pageNumbers.map((number) =>
          currentPage === number ? (
            <button
              key={number}
              className="px-6 py-3 mx-1 text-2xl text-purple-100 bg-purple-900 rounded-xl hover:bg-purple-700"
              onClick={() => {
                setCurrentPage(number);
              }}
            >
              {number}
            </button>
          ) : (
            <button
              key={number}
              onClick={() => {
                setCurrentPage(number);
              }}
              className="px-6 py-3 mx-1 text-2xl text-purple-100 bg-purple-400 rounded-xl hover:bg-purple-700"
            >
              {number}
            </button>
          )
        )}
      </ul>
    </nav>
  );
};

export default Pagination;
