import React, {useEffect, useState} from 'react';
import YoutubeChart from "./YoutubeChart";
import Select from "react-select";

const filterOptions = [{label: "All Videos", value: "all"}, {label: "Dev Rel Videos", value: "isDA"}, {label: "Realm Videos", value: "isRealm"}];

const ChartsYoutube = () => {
  const [filter, setFilter] = useState("all");
  const [filterVideos, setFilterVideos] = useState({});
  const [filterStats, setFilterStats] = useState({});

  useEffect(() => {
    if (filter.value === "all") {
      setFilterVideos({});
      setFilterStats({});
    } else if (filter.value === "isDA") {
      setFilterVideos({'isDA': true});
      setFilterStats({'video.isDA': true});
    } else if (filter.value === "isRealm") {
      setFilterVideos({'isRealm': true});
      setFilterStats({'video.isRealm': true});
    }
  }, [filter])

  return <>
    <div className="flex justify-center items-center mb-4">
      <label className="text-xl font-sans mr-3" for="filter">Select filter:</label>
      <Select id="filter" className="w-60 border rounded text-tolopea-600 active:border-tolopea-800" options={filterOptions} onChange={setFilter}
              defaultValue={filterOptions[0]}/>
    </div>
    <div className="flex flex-wrap items-center justify-center">
      <YoutubeChart height={'300px'} width={'400px'} filter={filterVideos} chartId={'cac31b58-12f0-452d-9fd6-947c565c0ca4'}/>
      <YoutubeChart height={'600px'} width={'800px'} filter={filterVideos} chartId={'27bdfe10-5202-4fb7-b234-41595c8d8330'}/>
      <YoutubeChart height={'600px'} width={'800px'} filter={filterVideos} chartId={'c9d633e4-1a93-4220-a6df-ff094097b055'}/>
      <YoutubeChart height={'600px'} width={'800px'} filter={filterStats} chartId={'6e868a4c-ac63-4fbd-b846-e880ee007cb2'}/>
      <YoutubeChart height={'600px'} width={'800px'} filter={filterStats} chartId={'2c108d62-f268-4670-961f-7de02888ffa8'}/>
      <YoutubeChart height={'600px'} width={'800px'} filter={filterStats} chartId={'7b5ac9bd-0083-47ee-8dfc-3b2c657a8b5e'}/>
      <YoutubeChart height={'600px'} width={'800px'} filter={filterStats} chartId={'9a21942a-13d1-4708-a4da-0bd0b6f666cf'}/>
      <YoutubeChart height={'600px'} width={'800px'} filter={filterStats} chartId={'e0ee0416-155f-4221-90f4-0fc07dfcc05e'}/>
      <YoutubeChart height={'600px'} width={'800px'} filter={filterStats} chartId={'20edc708-452c-4731-bd10-94d9e07d95e8'}/>
      <YoutubeChart height={'600px'} width={'800px'} filter={filterStats} chartId={'8860a450-dedf-461e-8ac8-9c924db4cc85'}/>
      <YoutubeChart height={'600px'} width={'800px'} filter={filterStats} chartId={'ebe5d7e5-b411-48b4-89d5-3cd1c70406af'}/>
      <YoutubeChart height={'600px'} width={'800px'} filter={filterStats} chartId={'3b64e9bc-1d52-4cf3-b2c6-58fc0a4a4f02'}/>
      <YoutubeChart height={'600px'} width={'800px'} filter={filterStats} chartId={'db89cc3b-78fd-4c7e-9f9d-6e3cb80c3828'}/>
      <YoutubeChart height={'600px'} width={'800px'} filter={filterStats} chartId={'8ca60b8b-96ee-4714-b95f-437b17936e89'}/>
      <YoutubeChart height={'600px'} width={'800px'} filter={filterStats} chartId={'0a77a106-2354-425a-94f5-ad92055d5670'}/>
      <YoutubeChart height={'800px'} width={'1300px'} filter={filterStats} chartId={'89b26e0d-461a-4417-85f0-345f99cb1141'}/>
      <YoutubeChart height={'700px'} width={'1300px'} filter={filterStats} chartId={'c0b296f6-548d-4e67-92b3-8af96101fef8'}/>
      <YoutubeChart height={'700px'} width={'1300px'} filter={filterStats} chartId={'68295cc2-5b3a-43d3-9aa8-d14dc52f796a'}/>
    </div>
  </>;
};

export default ChartsYoutube;
