import './App.css';
import React, { useContext } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import * as Realm from "realm-web";

// COMPONENTS
import Navbar from './components/Navbar';

// PAGES
import HomePage from './pages/Home';
import ChartsPage from './pages/Charts';
import BlogsPage from './pages/Blogs.js';
import IndividualBundlePage from './pages/Individual-Bundle';
import InputPage from './pages/Input';
import SignInPage from './pages/SignIn';
import ConfirmPage from './pages/Confirm';
import EditBundlePage from './pages/Edit-Bundle';
import UpdateAuthorPage from './pages/UpdateAuthor';
import AuthorPage from './pages/Author';
import BundlersPage from './pages/Bundlers'

//HOOK
import { CurrentUserContext } from './components/CurrentUserContext';
import { CurrentUserProvider } from './components/CurrentUserContext';
import { useCurrentUser } from './components/CurrentUserContext';


const REALM_APP_ID = "da_share-khckc";
const app = new Realm.App({ id: REALM_APP_ID });

export const AppContext =  React.createContext(app);

export const useApp = ()=>{
  const app = useContext(AppContext);
  return app;
}


const ProtectedRoute =(props)=>{
  const { currentUser } = useCurrentUser();
 // console.log("IN PROTECTED ROUTE");
  if (!currentUser) {
    return <Redirect to='/signin'></Redirect>
}
  return <Route {...props}></Route>
}

const BundlerProtectedRoute =(props)=>{
  const { currentUser, bundlers} = useContext(CurrentUserContext);
  // console.log("IN BUNDLER PROTECTED ROUTE");
  // console.log(bundlers);
  if (currentUser) {
    console.log(currentUser.profile.email.toLowerCase());

    if (!bundlers.includes(currentUser.profile.email.toLowerCase())) {  // bundlers.includes(currentUser.profile.email

      console.log("IN BUNDLER PROTECTED ROUTE DOES NOT INCLUDE CURRENT USER ");
      return <Redirect to='/'></Redirect>
    } else {
      return <Route {...props}></Route>
    }}
  else {
   // console.log("IN BUNDLER PROTECTED ROUTE INCLUDES CURRENT USER");
    return <Route {...props}></Route>
  }
}


function App() {

  return (
    <AppContext.Provider
      value={app}
    >
    <CurrentUserProvider>
      <Router>
        <Navbar/>
        <Switch>
          <ProtectedRoute exact path='/' component={HomePage}/>
          <ProtectedRoute exact path='/blogs' component={BlogsPage}/>
          <ProtectedRoute exact path='/charts/devhub' component={ChartsPage}/>
          <ProtectedRoute exact path='/charts/youtube' component={ChartsPage}/>
          <BundlerProtectedRoute exact path='/input' component={InputPage}/>
          <ProtectedRoute exact path='/bundlers' component={BundlersPage}/>
          <Route exact path='/signin' component={SignInPage}/>
          <Route exact path='/confirm' component={ConfirmPage}/>
          <ProtectedRoute exact path='/:bundleId' component={IndividualBundlePage}/>
          <ProtectedRoute exact path='/edit/:bundleId' component={EditBundlePage}/>
          <ProtectedRoute exact path='/author/:authorId' component={AuthorPage}/>
          <ProtectedRoute exact path='/updateAuthor/:authorId' component={UpdateAuthorPage}/>
        </Switch>
      </Router>
    </CurrentUserProvider>
    </AppContext.Provider>
  );
}

export default App;
