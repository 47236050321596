import React from 'react'

const Badge = ({itemArray}) => {
    
    return (
        <div className="flex space-x-3">
        { itemArray.map((item, idx) => (
            <div key={idx} className="px-2 py-1 my-auto text-center text-white bg-pink-600 rounded-full"> <h4>{item}</h4></div>
        ))}   
        </div> 
        
    )
}

export default Badge
