import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import {AppContext} from '../App.js';
import {CurrentUserContext} from '../components/CurrentUserContext';

import HomeIcon from '../images/home_icon.png';
import BlogIcon from '../images/devhub_icon.png';
import TeamIcon from '../images/Team.png';

import ChartsIcon from '../images/charts_icon.png';
import FormIcon from '../images/input_icon.png';
import UsersIcon from '../images/users_icon.png';
import Leaf from '../images/leaf.png';


const Navbar = () => {
  const app = useContext(AppContext);
  let ENABLEBUNDLER = false;
  const {currentUser, setCurrentUser, bundlers} = useContext(CurrentUserContext);
  if (currentUser) {
    //  console.log("IN NAVBAR... BUNDLERS");
    ENABLEBUNDLER = bundlers.includes(currentUser.profile.email.toLowerCase());

      console.log("ENABLEBUNDLER TESTING HERE", ENABLEBUNDLER);
  }

  return (<div>
      <div className="container flex items-center justify-between pb-2 mx-auto">
        <div id="left">

          <Link to='/' className="flex items-center space-x-3">
            <img className="mx-auto mb-2 content-image" src={Leaf} alt="bundles"/>
            <h1 className="sm:text-lg lg:text-3xl">MongoDB Bundles</h1>
          </Link>
        </div>

        <div id="right" className="flex items-center space-x-4 lg:space-x-20">
          <div className="">
            <Link to='/'>
              <img className="mx-auto mb-2 content-image" src={HomeIcon} alt="bundles"/>
            </Link>
            <Link className="mx-auto" to='/'>BUNDLES
            </Link>
          </div>

          <div className="">
            <Link to='/blogs'>
              <img className="mx-auto mb-2 content-image" src={BlogIcon} alt="blogs"/>
            </Link>
            <Link className="mx-auto" to='/blogs'>BLOGS
            </Link>
          </div>

          <div className="">
            <Link to='/bundlers'>
              <img className="mx-auto mb-2 content-image" src={TeamIcon} alt="team"/>
            </Link>
            <Link className="mx-auto" to='/bundlers'>BUNDLERS
            </Link>
          </div>

          <div className="">
            <Link to='/charts/devhub'>
              <img className="mx-auto mb-2 content-image" src={ChartsIcon} alt="charts"/>
            </Link>
            <Link className="mx-auto" to='/charts/devhub'>CHARTS
            </Link>
          </div>

          {ENABLEBUNDLER ? <div className="">
            <Link to='/input'>
              <img className="mx-auto mb-2 content-image" src={FormIcon} alt="form"/>
            </Link>
            <Link className="mx-auto" to='/input'>UPLOAD
            </Link>
          </div> : null}

          {currentUser ? <div className="">
            <img className="w-1/2 mx-auto mb-2" src={UsersIcon} alt="user"/>
            <Link to='/signin' className="mx-auto" onClick={async () => {
              await currentUser.logOut();
              setCurrentUser(app.currentUser);
            }}>
              SIGN OUT
            </Link>
          </div> : null}
        </div>
      </div>
    </div>)
}

export default Navbar
