import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from "axios";
import {useCurrentUser} from '../components/CurrentUserContext.js';
import DevTeam from '../images/devteam.png'
import MediaCard from '../components/MediaCard'
import Badge from '../components/Badge'
import AuthorSection from '../components/AuthorSection'

// HOOK
import { useBundleFetch } from '../hooks/useBundleFetch';

const IndividualBundlePage = () => {

    const { bundleId } = useParams();
    const [bundleDelete, setBundleDelete] = useState(false);
    const { currentUser } = useCurrentUser();
    const history = useHistory();
   
    const DELETE_ENDPOINT = "https://webhooks.mongodb-realm.com/api/client/v2.0/app/da_share-khckc/service/da_content/incoming_webhook/removeBundle";
    
    const{ bundleState, authorState } = useBundleFetch(bundleId); 
    console.log(authorState);


    const { owner } = bundleState;
    const BUNDLEOWNER = (currentUser.profile.email.toLowerCase() === owner?.toLowerCase());                       //owner?.toLowerCase());

    console.log("FOR LAUREN");
    console.log("current user email: " + currentUser.profile.email);
    console.log("OWNER: " + owner);
    console.log("BUNDLEOWNER: " + BUNDLEOWNER);


    // CHECK TO SEE IF THE BUNDLE EXISTS OR HAS BEEN DELETED
    if (bundleState===null){
        console.log("EMPTY");
        return (
            <>
                <div className="flex text-center bg-gradient-to-r from-indigo-800 to-indigo-900 h-36">
                    <div className="mx-auto my-auto text-3xl font-bold text-white font-body">Empty Bundle</div>
                </div>
                <div className="mt-20 text-4xl text-center font-body text-william-600">
                This bundle does not exist.
                </div><br></br>
                <div className="mt-20 text-4xl text-center font-body text-william-600">
                    Go back to home page to check for more awesome content bundles.
                </div>
            </>
        );
    }
    // THE BUNDLE EXISTS. CAN DESTRUCTURE BUNDLE.
    const {blogs, videos, podcasts, products, author } = bundleState;
   
    const deleteBundle = async() =>{
        console.log(bundleId);
        await axios.post(DELETE_ENDPOINT+`?id=${bundleId}`);
        console.log("Deleting Bundle");
        setBundleDelete(true);
       
    }

    const editBundle = async() =>{
        console.log("Editing Bundle: " + bundleId);
        history.push(`/edit/${bundleId}`);  
   }
  
    return (
        <>
        <div className="text-center bg-gradient-to-r from-indigo-800 to-indigo-900 h-36">
            <div className="py-8 text-3xl font-bold text-white font-body">{bundleState.title}</div>
            <div className="text-lg font-bold text-white font-body">{bundleState.author}</div> 
        </div>
        <div className="container mx-auto font-body">

            {!bundleDelete ?
                <>
                <div id="TOPROW" className="flex p-10 pb-0 space-x-4">
                    <div id="TOPLEFT" className="w-2/3 p-5 space-y-2 rounded ">
                        <h1 className="text-4xl font-bold">{bundleState.title}</h1>
                        <h1 className="mb-8 text-xl">{bundleState.description}</h1>
                        <br></br>
                        <hr style={{
                            color: 'indigo',
                            backgroundColor: 'indigo',
                            height: 2,
                            borderColor : 'indigo'
                        }}/>
                        <br></br>
                        { bundleState.date_presented !== "" ?<h1 className="">Date Presented: {bundleState.date_presented}</h1> :''}
                        { bundleState.git_link !== "" ? <h1 className="">Git Repo:  
                        <a href={bundleState.git_link} target="_blank" rel="noopener noreferrer">&nbsp;&nbsp;&nbsp;{bundleState.git_link}</a></h1> :''}
                        { bundleState.slides !== "" ? <h1 className="">Slides Available:  
                        <a href={bundleState.slides} target="_blank" rel="noopener noreferrer">&nbsp;&nbsp;&nbsp;{bundleState.slides}</a></h1> :''}
                    </div>

        
                    <div id="TOPRIGHT" className="flex flex-wrap items-center justify-center w-1/3 p-10 mx-auto overflow">
                    {
                        (Array.isArray(products) && products.length) ? 
                    
                        <Badge
                            itemArray={products}
                        /> :""
                    }
                    { ((bundleState.instructions) &&  (bundleState.instructions!== "")) ?<h1 className="text-lg text-indigo-700 "><b>Instructions: </b>{bundleState.instructions}</h1> :''}
                    </div>
                </div>
        
                <div id="SECONDROW" className="flex justify-between w-full">
                    <div id="MediaContent" className="w-2/3 p-5 rounded bg-blue">
                        {
                            (Array.isArray(blogs) && blogs.length) ? 
                        
                            <React.Fragment>
                                <MediaCard 
                                    itemArray={blogs}
                                    content_type="blog"
                                /><br></br>
                            </React.Fragment> :""
                        }
                        {
                            (Array.isArray(videos) && videos.length) ? 
                            <React.Fragment>    
                                <MediaCard 
                                        itemArray={videos}
                                        content_type="video"
                                /><br></br>
                            </React.Fragment> :""
                        }
                        {
                            (Array.isArray(podcasts) && podcasts.length) ? 
                                <React.Fragment>
                                    <MediaCard 
                                        itemArray={podcasts}
                                        content_type="podcast"
                                    /><br></br>
                                </React.Fragment> :""
                        }             
                    </div> 
                    
                    {   authorState ? 
                            <AuthorSection
                                name = {authorState?.name}
                                title = {authorState?.title}
                                image = {authorState?.image}
                                twitter={authorState?.twitter}
                                email={authorState?.email}
                                bio={authorState?.bio}
                                authorId={authorState?._id}
                            >
                            </AuthorSection>
                        :   <div className="flex flex-col justify-between w-1/3 p-4">
                                <h1 className="p-6 mx-auto text-3xl text-center uppercase">{bundleState.author}</h1>
                                <img className="object-scale-down mx-auto" src={DevTeam} alt="Developer" />
                            </div>
                    }
                
                    
                </div>    

                    { BUNDLEOWNER ? 

                        <div className="flex mt-10 justify-evenly">
                            <button className="flex items-center w-40 h-20 px-6 py-4 text-3xl text-white transition duration-300 rounded-lg shadow-xl bg-mongo-600 hover:bg-mongo-700"
                                    onClick={editBundle}
                                >Edit<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                            </svg>
                            </button>
                            
                            <button className="flex items-center w-40 h-20 px-6 py-4 text-2xl text-white transition duration-300 rounded shadow-xl bg-rose-600 hover:bg-rose-700"
                                    onClick={deleteBundle}
                                >Delete <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                            </svg>
                            </button>
                        </div> 
                        :''
                    }
                    
            </> 
            : <div className="mt-20 text-4xl text-center font-body text-william-600">{bundleState.title} has been deleted.</div>
            }
        </div>
    </>
    )
}

export default IndividualBundlePage
